import React from 'react';
import Button from '../../../rnbc/components/button';
import { alert } from '../../shared/popup';
import ActivityIndicator from '../../../rnbc/components/activityIndicator';
import Icon from '../../../rnbc/icon';
import Alert from '../../../rnbc/components/alert';
import { errorParser } from '../../shared/errorParser';
import { FORM_REVISION_UNSET } from './_gql';
import { client } from '../../shared/apollo';
import styles from '../../../rnbc/styles';
import { GET_REVISION_ROWS_REVAMPED } from '../Revisions/_gql';
import NextPrevRevButton from './NextPrevRevButton';

export default ({
  projectId,
  formId,
  revisionId,
  onDelete,
  style = [],
  ...props
}) => {
  return (
    <Button
      {...props}
      style={[styles.border(0)].concat(style)}
      color={'danger'}
      onPress={async () => {
        try {
          await alert('', 'Delete Revision?');
          ActivityIndicator.show();
          await client.mutate({
            mutation: FORM_REVISION_UNSET,
            variables: {
              id: revisionId
            },
            update: (cache, { data }) => {
              try {
                const {
                  variables,
                  parseConnection
                } = NextPrevRevButton.ListQueryFetcherCache;
                const _query = GET_REVISION_ROWS_REVAMPED(projectId, formId),
                  _variables = {
                    ...variables,
                    cursor: variables.currentCursor,
                    limits: variables.currentLimits
                  };
                const data =
                  cache.readQuery({
                    query: _query,
                    variables: _variables
                  }) || {};

                const { nodes = [] } = parseConnection(data);
                const index = nodes.findIndex(node => node.id === revisionId);
                if (!!~index) {
                  console.log("delete cache index", index);
                  nodes.splice(index, 1);
                  cache.writeQuery({
                    query: _query,
                    variables: _variables,
                    data: { ...data }
                  });
                }
              } catch (e) {
                console.log(e);
              }
            }
          });
          onDelete({ id: revisionId });
        } catch (e) {
          Alert.danger(errorParser(e));
        } finally {
          ActivityIndicator.hide();
        }
      }}
    >
      <Icon name={'times'} />
    </Button>
  );
};
