import React from 'react';
import {
  FormGroup,
  InputGroup,
  InputGroupAppend,
  Label,
  ModalizedInputDate,
  ModalizedInputTime,
  Small
} from '../../rnbc/components/form';
import { Row } from '../../rnbc/components/layout';
import styles from '../../rnbc/styles';
import Icon from '../../rnbc/icon';
import Text from '../../rnbc/components/text';
import moment from 'moment';
import FieldLoading from './fieldLoading';
import Button from '../../rnbc/components/button';
import { selectableMinuteInterval } from '../shared/isTC';
import FieldUserGroupPositionsRemark from './FieldUserGroupPositionsRemark';

export default ({
  label,
  disabled,
  dateTime: value,
  required,
  minimum,
  onChange = _ => _,
  _sync,
  description,
  userGroup,
  userPositions
}) => {
  const minDay = minimum === 'today' ? moment().toISOString() : minimum;
  return (
    <FormGroup>
      {!!_sync && (
        <FieldLoading style={[styles.absolute({ top: -5, right: 0 })]} />
      )}
      {!!label && (
        <Row fy={'center'}>
          <Label style={[styles.marginY(5, !!description ? 0 : 5)]}>
            {label} <Text color={'danger'}>{!!required && '**'}</Text>
          </Label>
          <FieldUserGroupPositionsRemark
            userGroup={userGroup}
            userPositions={userPositions}
          />
        </Row>
      )}
      {!!description && (
        <Small style={[styles.marginBottom(5)]}>{description}</Small>
      )}
      <Row>
        <InputGroup style={[styles.flex(1), { maxWidth: 500 }]}>
          <ModalizedInputDate
            disabled={disabled}
            minDay={minDay}
            value={value || undefined}
            style={[styles.flex(1)]}
            onChange={val => {
              onChange(
                moment(val)
                  .set({ second: 0, millisecond: 0 })
                  .toISOString()
              );
            }}
          />

          <InputGroupAppend>
            <Icon name={'calendar-alt'} />
          </InputGroupAppend>
          <ModalizedInputTime
            minuteInterval={selectableMinuteInterval}
            disabled={disabled}
            value={value || undefined}
            style={[styles.flex(1)]}
            onChange={val => {
              onChange(
                moment(val)
                  .set({ second: 0, millisecond: 0 })
                  .toISOString()
              );
            }}
          />
          <InputGroupAppend>
            <Icon name={'clock'} />
          </InputGroupAppend>
        </InputGroup>
        {!!value && !disabled && (
          <Button
            disabled={disabled}
            onPress={() => onChange(undefined)}
            transparent
            color={'danger'}
          >
            <Icon name={'times'} />
          </Button>
        )}
      </Row>
    </FormGroup>
  );
};
