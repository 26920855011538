import React, { Fragment, Component } from 'react';
import styles from '../../../rnbc/styles';
import Loading from '../../../rnbc/components/loading';
import Button from '../../../rnbc/components/button';
import RefreshButton from '../../../rnbc/components/refreshButton';
import Text from '../../../rnbc/components/text';
import Nav, { NavItem, Padder } from '../../../rnbc/components/nav';
import Icon from '../../../rnbc/icon';
import NavHeader from '../../../rnbc/components/navHeader';
import {
  Modal,
  ReactApollo,
  AsyncStorage,
  View,
  OS,
  Animated,
  Sentry
} from '../../../plugins';
import { Col, Row, Responsively } from '../../../rnbc/components/layout';
import ScrollHeaderLayout from '../../../rnbc/components/scrollHeaderLayout';
import Header from './_header';
import Stage from './_stage';
import ConfidentialFiles from './_confidentialFiles';
import { GET_REVISION } from './_gql';
import Alert from '../../../rnbc/components/alert';
import { errorParser } from '../../shared/errorParser';
import { BottomPadder } from '../../../rnbc/components/iphonePadder';
import NextPrevRevButton from './NextPrevRevButton';
import ExportedFileField from '../../component/exportedFileField';

export default class extends Component {
  state = {
    showClientConfidentialModal: false,
    dataset: {},
    current: undefined
  };
  _mounted = false;
  refetch = undefined;
  async componentDidMount() {
    this._mounted = true;
    try {
      const json = (await AsyncStorage.getItem('dataset')) || '{}';
      this.setState({ dataset: JSON.parse(json) });
    } catch (e) {}
  }
  async componentWillUnmount() {
    const { dataset } = this.state;
    this._mounted = false;
    try {
      await AsyncStorage.setItem('dataset', `${JSON.stringify(dataset)}`);
    } catch (e) {}
  }
  onSearchPress = () => {
    const { history, match: { params } = {} } = this.props,
      { projectId, formId } = params || {};
    history.push({
      pathname: `/Search/${projectId}/${formId}`
    });
  };
  onBackPress = () => {
    const { history } = this.props;
    NavHeader.lastBackReferrer = history.location.pathname;
    NextPrevRevButton.resetFetcherCache();
    history.goBack();
  };
  onRevisionCreate = (revisionId) => {
    if (!revisionId) return;
    const {
        history,
        match: { params } = {},
        location: { state: { title } } = {}
      } = this.props,
      { projectId, formId } = params || {};
    history.replace({
      pathname: `/Revision/${projectId}/${formId}/${revisionId}`,
      state: {
        title
      }
    });
  };
  onRevisionDelete = ({ id }) => {
    const { history } = this.props;
    NavHeader.lastBackReferrer = history.location.pathname;
    NextPrevRevButton.resetFetcherCache();
    history.goBack();
  };
  toggleClientConfidentialModal = () => {
    const { showClientConfidentialModal } = this.state;
    this.setState({
      showClientConfidentialModal: !showClientConfidentialModal
    });
  };
  onNavChange = (i) => {
    this.setState({ current: i });
  };
  render() {
    let { match: { params } = {} } = this.props,
      { projectId, formId, revisionId, stageId } = params || {},
      { dataset, current } = this.state;
    return (
      <ReactApollo.Query
        query={GET_REVISION(projectId, formId, revisionId)}
        fetchPolicy={'cache-and-network'}
        returnPartialData
      >
        {({ loading, data, error, refetch }) => {
          if (!this._mounted) return null;
          let isCacheAvailable = !!data && !!data.me;
          this.refetch = refetch;
          if (!!error) {
            Sentry.captureMessage(`Revision Error: ${error.message}`);
            Alert.danger(errorParser(error));
            return (
              <Fragment>
                {this.renderNavHeader()}
                <Loading />
              </Fragment>
            );
          }
          if (!!loading && !isCacheAvailable)
            return (
              <Fragment>
                {this.renderNavHeader()}
                <Loading />
              </Fragment>
            );

          const { me } = data || {},
            project = (me || {})[`p_${projectId.replace(/-/g, '_')}`] || {},
            form = project[`f_${formId.replace(/-/g, '_')}`] || {},
            revision = form[`rev_${revisionId.replace(/-/g, '_')}`] || {},
            {
              stages = [],
              revision: rev,
              currentStageIds,
              lockedBy,
              exportedFile,
              finalized,
              withdrawnBy,
              lastSubmittedAt
            } = revision;

          let defaultTab = stages.findIndex(
            (stage) =>
              (stage.id || '').replace(
                new RegExp(`^(.*)-${revisionId}$`),
                '$1'
              ) === (stageId || (currentStageIds || [''])[0])
          );
          if (!~defaultTab) defaultTab = 0;
          const headerHeight = 140;
          let currentStage =
            stages[current === undefined ? defaultTab : current];

          /* regression #63 */
          if (!currentStage) {
            const _json = JSON.stringify(data);
            if (_json === '{}') {
              console.error(
                'Potential cache miss event:',
                JSON.stringify({
                  loading,
                  _json,
                  error
                })
              );
              Alert.danger('Unexpected Error, Please Login Again');
              throw new Error(
                'Unknown Bug, maybe cache issue, this should trigger all caches reset.'
              );
            }
          }

          if (!!window && !!window.crash_test) {
            throw new Error('Crash Test');
          }

          return (
            <Fragment>
              {this.renderNavHeader(
                (me || {}).group,
                form.name,
                exportedFile,
                lastSubmittedAt,
                loading
              )}
              <ScrollHeaderLayout
                heightRange={[
                  headerHeight + styles.header.height + 10,
                  styles.header.height + 10
                ]}
                onRefresh={() => {
                  this.refetch && this.refetch();
                }}
                renderHeader={(y) => {
                  const aniHeight = y.interpolate({
                    inputRange: [0, 1],
                    outputRange: [headerHeight, 0]
                  });
                  return (
                    <Col>
                      <Animated.View
                        style={{ overflow: 'hidden', height: aniHeight }}
                      >
                        <View style={[{ height: headerHeight }]}>
                          <Header
                            me={me}
                            project={project}
                            form={form}
                            revision={revision}
                            onRevisionCreate={this.onRevisionCreate}
                            onRevisionDelete={this.onRevisionDelete}
                          />
                        </View>
                      </Animated.View>
                      <Row
                        style={[
                          styles.backgroundColor('white'),
                          styles.paddingTop(10),
                          OS === 'web' && { overflow: 'auto' }
                        ]}
                      >
                        <Nav
                          current={current === undefined ? defaultTab : current}
                          onChange={this.onNavChange}
                        >
                          {stages.map(({ label = '', enabled }, i) => {
                            return (
                              <NavItem
                                key={i}
                                title={
                                  <Text
                                    style={[
                                      !enabled && {
                                        color: '#ccc',
                                        fontStyle: 'italic'
                                      }
                                    ]}
                                  >
                                    {label}
                                  </Text>
                                }
                                color={enabled ? 'primary' : 'secondary'}
                              />
                            );
                          })}
                        </Nav>
                        <Padder style={[styles.flex(1)]} />
                      </Row>
                    </Col>
                  );
                }}
              >
                {!!currentStage ? (
                  <Stage
                    me={me}
                    dataset={dataset}
                    projectId={projectId}
                    formId={formId}
                    version={rev}
                    revisionId={revisionId}
                    stage={currentStage}
                    finalized={finalized}
                    lockedBy={lockedBy}
                    withdrawnBy={withdrawnBy}
                  />
                ) : (
                  <Loading />
                )}
                <BottomPadder />
                <View style={[{ height: 100 }]} />
              </ScrollHeaderLayout>
              {this.renderClientConfidentialModal(revision || {})}
            </Fragment>
          );
        }}
      </ReactApollo.Query>
    );
  }
  renderNavHeader(
    group = undefined,
    title = '',
    exportedFile,
    lastSubmittedAt,
    loading
  ) {
    const { history, match: { params } = {} } = this.props,
      { projectId, formId, revisionId } = params || {};
    const __renderExtraButtons = (hideSearchButton) => {
      return (
        <Fragment>
          <RefreshButton
            isLoading={loading}
            color={'light'}
            onPress={() => {
              this.refetch && this.refetch();
            }}
          />
          {!!~['INSPECTORS', 'CONSULTANTS'].indexOf(group) && (
            <Button
              transparent
              color={'light'}
              onPress={this.toggleClientConfidentialModal}
            >
              <Icon name={'file'} />
            </Button>
          )}
          {
            <ExportedFileField
              exportedFile={exportedFile}
              fileUpdatedAt={lastSubmittedAt}
            />
          }
          {!hideSearchButton && (
            <Button transparent color={'light'} onPress={this.onSearchPress}>
              <Icon name={'search'} />
            </Button>
          )}
        </Fragment>
      );
    };
    return (
      <NavHeader
        color={'primary'}
        back
        leftComponent={
          <Fragment>
            <Button
              transparent
              color={`anti-primary`}
              onPress={this.onBackPress}
            >
              <Icon name={'arrow-left'} />
            </Button>
            {/* Don't deploy this function */}
            {false && (
              <NextPrevRevButton
                history={history}
                projectId={projectId}
                formId={formId}
                revisionId={revisionId}
                title={title}
              />
            )}
          </Fragment>
        }
        history={history}
        title={title}
        rightComponent={
          <Responsively
            layout={Row}
            fy={'center'}
            children={{
              sm: __renderExtraButtons(true),
              md: __renderExtraButtons()
            }}
          />
        }
      />
    );
  }
  renderClientConfidentialModal({
    finalFinalizedAt,
    confidentialExportedFile,
    lastSubmittedAt,
    confidentialFiles = []
  }) {
    const { match: { params } = {} } = this.props;
    const { revisionId } = params || {};
    const { showClientConfidentialModal } = this.state;
    if (!showClientConfidentialModal) return null;
    return (
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        visible={true}
        onRequestClose={this.toggleClientConfidentialModal}
      >
        <ConfidentialFiles
          files={confidentialFiles}
          revisionId={revisionId}
          finalFinalizedAt={finalFinalizedAt}
          confidentialExportedFile={confidentialExportedFile}
          confidentialExportedFileUpdatedAt={lastSubmittedAt}
          onBackPress={this.toggleClientConfidentialModal}
        />
      </Modal>
    );
  }
}
