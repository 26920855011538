import { ApolloBoost } from '../../../plugins';
import { insertStr } from '../../shared/isTC';
const { gql } = ApolloBoost;

export const GET_REVISION = (projectId, formId, revisionId) => gql`
  query {
    me {
      id
      name
      email
      group
      position
      p_${projectId.replace(/-/g, '_')}: project(id: "${projectId}") {
        id
        f_${formId.replace(/-/g, '_')}: form(id: "${formId}") {
          id
          image {
            key
            bucket
            region
          }
          template {
            id
            enableNumericRevision
          }
          contractNumber
          name
          latestVersion
          rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
            id
            revision
            lastRevision
            sequentialNumber
            currentStageIds
            exportedFile {
              downloadUrl
              createdAt
            }
            confidentialExportedFile {
              downloadUrl
              createdAt
            }
            finalFinalizedAt
            stages {
              id
              label
              enabled
              fields {
                id
                label
                description
                required
                disabled
                showInList
                userGroup
                userPositions
                updatedAt
                updatedBy {
                  id
                  name
                  position
                  group
                }
                ... on TextField {
                  multiline
                  text
                  values
                }
                ... on LocationField {
                  maps {
                    id
                    name
                    image
                    locations {
                      name
                      x
                      y
                      radius
                      latitude
                      longitude
                      altitude
                      level
                      grid
                      area
                    }
                  }
                  location {
                    name
                    x
                    y
                    radius
                    latitude
                    longitude
                    altitude
                    level
                    grid
                    area
                  }
                }
                ... on SubmitButton {
                  id
                }
                ... on DateTimeField {
                  minimum
                  maximum
                  dateTime
                }
                ... on PreviewField {
                  downloadUrl
                }
                ... on SignatureField {
                  signature
                }
                ... on UserField {
                  users {
                    id
                    name
                    position
                    email
                  }
                  requireNoted
                  noted
                }
                ... on NotifyField {
                  users {
                    id
                    name
                    position
                    email
                  }
                  message
                }
                ... on WithdrawButton {
                  id
                }
                ... on FileField {
                  numberOfFiles
                  allow
                  files {
                    key
                    bucket
                    region
                    description
                    createdAt
                  }
                }
                ... on ApprovalField {
                  status
                  numberOfButton
                }
                ... on ChopField {
                  chopped
                  image
                }
                ... on EnumField {
                  value
                  values
                }
              }
            }
            updatedAt
            lastSubmittedAt
            createdAt
            currentStageIds
            finalized
            confidentialFiles {
              key
              bucket
              region
              description
            }
            lockedBy {
              id
              name
              group
            }
            withdrawnBy {
              id
              name
              position
              group
            }
          }
        }
      }
    }
  }
`;
export const FORM_REVISION_SYNC = (revisionId, stageId, fieldId) => gql`
  mutation($data: AWSJSON!) {
    formRevisionSync(id: "${revisionId}", fields: [{ fieldId: "${fieldId}", data: $data }]) {
      id
      rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
        id
        s_${stageId.replace(/-/g, '_')}: stage(stageId: "${stageId}") {
          id
          fi_${fieldId.replace(/-/g, '_')}: field(fieldId: "${fieldId}") {
            id
            label
            description
            required
            showInList
            disabled
            userGroup
            updatedAt
            updatedBy {
              id
              name
              position
              group
            }
            ... on TextField {
              multiline
              text
              values
            }
            ... on LocationField {
              maps {
                id
                name
                image
                locations {
                  name
                  x
                  y
                  radius
                  latitude
                  longitude
                  altitude
                  level
                  grid
                  area
                }
              }
              location {
                name
                x
                y
                radius
                latitude
                longitude
                altitude
                level
                grid
                area
              }
            }
            ... on SubmitButton {
              id
            }
            ... on DateTimeField {
              minimum
              maximum
              dateTime
            }
            ... on PreviewField {
              downloadUrl
            }
            ... on SignatureField {
              signature
            }
            ... on UserField {
              users {
                id
                name
                email
                position
              }
              requireNoted
              noted
            }
            ... on NotifyField {
              users {
                id
                name
                email
                position
              }
              message
            }
            ... on WithdrawButton {
              id
            }
            ... on FileField {
              numberOfFiles
              allow
              files {
                key
                bucket
                region
                description
                createdAt
              }
            }
            ... on ApprovalField {
              status
              numberOfButton
            }
            ... on ChopField {
              chopped
              image
            }
            ... on EnumField {
              value
              values
            }
          }
        }
      }
    }
  }
`;

export const FORM_REVISION_LOCK = revisionId => gql`
  mutation {
    formRevisionLock(id: "${revisionId}") {
      id
      rev_${(revisionId || '').replace(
        /-/g,
        '_'
      )}: revision(id: "${revisionId}") {
        id
        lockedBy {
          id
          name
          group
          position
        }
      }
    }
  }
`;
export const FORM_REVISION_UNLOCK = revisionId => gql`
  mutation {
    formRevisionUnlock(id: "${revisionId}") {
      id
      rev_${(revisionId || '').replace(
        /-/g,
        '_'
      )}: revision(id: "${revisionId}") {
        id
        lockedBy {
          id
        }
      }
    }
  }
`;
export const FORM_REVISION_CREATE = gql`
  mutation(
    $projectFormId: ID!
    $sourceRevision: ID
    $copyDescription: Boolean
    $copyRevision: ID
  ) {
    formRevisionCreate(
      projectFormId: $projectFormId
      sourceRevision: $sourceRevision
      copyDescription: $copyDescription
      copyRevision: $copyRevision
    ) {
      id
    }
  }
`;
export const FORM_REVISION_WITHDRAWAL_REQUEST = revisionId => gql`
  mutation {
    formRevisionWithdrawalRequest(id: "${revisionId}") {
      id
      rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
        id
        withdrawnBy {
          id
          name
          group
          email
        }
      }
    }
  }
`;
export const FORM_REVISION_WITHDRAWAL_ACCEPT = revisionId => gql`
  mutation {
    formRevisionWithdrawalAccept(id: "${revisionId}") {
      id
      rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
        id
        finalized
        withdrawnBy {
          id
        }
        lockedBy {
          id
        }
        stages {
          id
          enabled
        }
      }
    }
  }
`;
export const FORM_REVISION_WITHDRAWAL_REJECT = revisionId => gql`
  mutation {
    formRevisionWithdrawalReject(id: "${revisionId}") {
      id
      rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
        id
        withdrawnBy {
          id
        }
      }
    }
  }
`;
export const FORM_REVISION_SUBMIT = revisionId => gql`
  mutation($submitButtonId: ID!, $stageId: ID!) {
    formRevisionSubmit(id: "${revisionId}", submitButtonId: $submitButtonId) {
      id
      rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
        id
        revision
        finalized
        currentStageIds
        stages {
          id
          enabled
        }
        stage(stageId: $stageId) {
          id
          enabled
          field(fieldId: $submitButtonId) {
            id
            updatedAt
            updatedBy {
              id
              name
              position
              group
            }
          }
        }
      }
    }
  }
`;
export const FORM_REVISION_NOTIFY = gql`
  mutation($notifyFieldId: ID!, $revisionId: ID!) {
    formRevisionNotify(id: $revisionId, notifyFieldId: $notifyFieldId)
  }
`;
export const FORM_REVISION_CONFIDENTIAL_FILE_SET = revisionId => gql`
  mutation($index: Int!, $file: S3ObjectInput) {
    formRevisionConfidentialFileSet(
      id: "${revisionId}"
      index: $index
      file: $file
    ) {
      id
      rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
        id
        confidentialFiles {
          key
          bucket
          region
          description
        }
      }
    }
  }
`;
export const CONFIDENTIAL_FINAL_FINALIZE = revisionId => gql`
  mutation {
    formRevisionConfidentialFinalize(id: "${revisionId}") {
      id
      rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
        id
        finalFinalizedAt
      }
    }
  }
`;
export const FORM_REVISION_UNSET = gql`
  mutation($id: ID!) {
    formRevisionUnset(id: $id)
  }
`;
