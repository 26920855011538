import React, { Fragment } from 'react';
import {
  ImageBackground,
  View,
  TouchableOpacity,
  ReactApollo
} from '../../../plugins';
import { Row, Col } from '../../../rnbc/components/layout';
import Button from '../../../rnbc/components/button';
import Loading from '../../../rnbc/components/loading';
import Text from '../../../rnbc/components/text';
import List, { ListItem } from '../../../rnbc/components/list';
import H4 from '../../../rnbc/components/h4';
import H1 from '../../../rnbc/components/h1';
import Card, { CardBody, CardHeader } from '../../../rnbc/components/card';
import styles from '../../../rnbc/styles';
import { getLocation } from '../../shared/s3';
import Icon from '../../../rnbc/icon';
import { GET_BADGES } from './_gql';

export default ({
  projectId,
  id: formId,
  revision: { revision: rev = '' } = {},
  name,
  image,
  template,
  onFormPress,
  onCreatePress,
  onSearchPress,
  onReportPress,
  onListPress = _ => _,
  style
}) => {
  const { views } = template || {};
  return (
    <Card
      style={[
        styles.shadow,
        styles.backgroundColor('white'),
        styles.marginBottom(10)
      ].concat(style)}
    >
      <CardHeader style={[styles.padding(0)]}>
        <ImageBackground
          source={{ uri: getLocation(image) }}
          style={[
            styles.fill,
            styles.row,
            {
              maxHeight: 200,
              height: styles.vw(0.25).width
            }
          ]}
          resizeMode={'cover'}
        >
          <TouchableOpacity
            activeOpacity={0.5}
            onPress={onFormPress}
            style={[styles.row, styles.flex(1)]}
          >
            <Col
              style={[
                styles.padding(10),
                styles.backgroundColor('rgba(255,255,255,0.75)')
              ]}
            >
              <H1>{name}</H1>
              {!!rev ? <H4>Latest No. {rev}</H4> : <H4>View Existing Form</H4>}
            </Col>
            {Array(3)
              .fill(null)
              .map((_, i) => (
                <Col
                  key={i}
                  flex={1}
                  style={[
                    styles.backgroundColor('white'),
                    { opacity: 0.6 - i / 4 }
                  ]}
                />
              ))}
          </TouchableOpacity>
          <Col
            fy={'center'}
            fx={'center'}
            style={[styles.absolute({ right: 10, top: 0, bottom: 0 })]}
          >
            <Button
              color={'light'}
              onPress={onSearchPress}
              style={[
                styles.shadow,
                styles.rounded(17),
                styles.marginBottom(5),
                { width: 34, height: 34 }
              ]}
            >
              <Icon name={'search'} />
            </Button>
            <Button
              onPress={onCreatePress}
              style={[
                styles.shadow,
                styles.rounded(17),
                { width: 34, height: 34 }
              ]}
            >
              <Icon name={'plus'} />
            </Button>
            <Button
              color={'light'}
              onPress={onReportPress}
              style={[
                styles.shadow,
                styles.rounded(17),
                { width: 34, height: 34 }
              ]}
            >
              <Icon name={'chart-pie-alt'} />
            </Button>
          </Col>
        </ImageBackground>
      </CardHeader>
      <CardBody style={[styles.padding(0)]}>
        <ReactApollo.Query
          query={GET_BADGES(projectId, formId, views.map(view => view.id))}
          fetchPolicy={'cache-and-network'}
        >
          {({ loading, data }) => {
            if (!!loading && !data) return <Loading />;
            const { me } = data || {},
              form = ((me || {})[`p_${projectId.replace(/-/g, '_')}`] || {})[
                `f_${formId.replace(/-/g, '_')}`
              ];

            return (
              <List>
                {(views || []).map(({ id, label }, i) => {
                  const key = `revs_${id.replace(/\-/g, '_')}`;
                  const count = ((form || {})[key] || []) - 0;

                  return (
                    <ListItem
                      key={i}
                      border={i < views.length - 1}
                      source={{
                        uri: `https://oliveowl-esis-assets-pro.s3-ap-southeast-1.amazonaws.com/ts-book-icon.png`
                      }}
                      title={
                        <Row fy={'center'}>
                          <Text numberOfLines={1}>{label}</Text>
                          {!!count && (
                            <Text style={[styles.marginLeft(5)]}>
                              ({count})
                            </Text>
                          )}
                        </Row>
                      }
                      onPress={() => {
                        onListPress(id);
                      }}
                    />
                  );
                })}
              </List>
            );
          }}
        </ReactApollo.Query>
      </CardBody>
    </Card>
  );
};
