import { Config } from '../../plugins';
const isTC = !!(Config.REACT_APP_IS_TC - 0);

export default isTC;
export const insertStr = (TC, notTC = '') => {
  return isTC ? TC : notTC;
};
export const oneSignalPlayerIdPrefix = isTC ? 'TC' : 'DEFAULT';
export const selectableMinuteInterval = isTC ? 15 : 1;
export const preferDateFormat = isTC ? 'DD-MMM-YYYY' : 'YYYY-MM-DD';
export const sequentialNumberLength = isTC ? 7 : 4;
export const showConfidentialFinalize = !!isTC;
