import React from 'react';
import styles from '../../../rnbc/styles';
import { Flex, Row, Col } from '../../../rnbc/components/layout';
import ButtonGroup from '../../../rnbc/components/buttonGroup';
import Button from '../../../rnbc/components/button';
import Text from '../../../rnbc/components/text';
import Small from '../../../rnbc/components/form/small';
import Icon from '../../../rnbc/icon';
import HasBackground from '../../../rnbc/components/hasBackground';
import { getLocation } from '../../shared/s3';
import ActivityIndicator from '../../../rnbc/components/activityIndicator';
import { client } from '../../shared/apollo';
import {
  FORM_REVISION_LOCK,
  FORM_REVISION_UNLOCK,
  FORM_REVISION_CREATE
} from './_gql';
import Alert from '../../../rnbc/components/alert';
import { errorParser } from '../../shared/errorParser';
import { alert } from '../../shared/popup';
import { sequentialNumberLength } from '../../shared/isTC';
import RevisionDeleteButton from './RevisionDeleteButton';

export default ({
  me = {},
  project: { id: projectId } = {},
  form: { id, image, contractNumber, latestVersion = '', template = {} } = {},
  revision: {
    id: revisionId,
    revision: rev,
    lockedBy,
    sequentialNumber = '',
    lastRevision = ''
  } = {},
  onRevisionDelete = _ => _,
  onRevisionCreate = _ => _
}) => {
  const { enableNumericRevision } = template || {};
  const isNewSubmission =
    !!rev &&
    !!new RegExp(enableNumericRevision ? /^\d+$/ : /^\d+A$/).test(rev) &&
    rev !== '-';
  const isReSubmission = !!rev && !isNewSubmission && rev !== '-';
  const allowDelete =
    me.group === 'ADMINISTRATORS' ||
    ((!rev || rev === '-') && me.group === 'CONTRACTORS');
  const isLocked = !!lockedBy && lockedBy.group && lockedBy.group === me.group;
  const isLockedByMe = isLocked && me.id === lockedBy.id;

  return (
    <HasBackground
      source={{ uri: getLocation(image) }}
      shaded={0.3}
      shadedColor={'white'}
      style={[styles.column, styles.fill]}
    >
      <Row fy={'center'} style={[styles.padding(10)]}>
        <Col fy={'center'} flex={1}>
          {!!isNewSubmission && (
            <Small color={'success'} style={[styles.bold]}>
              New Submission
            </Small>
          )}
          {!!isReSubmission && (
            <Small color={'danger'} style={[styles.bold]}>
              Re-Submission
            </Small>
          )}
          <Text style={[styles.bold, styles.marginRight(10)]}>
            Rev. No. {rev}
          </Text>

          <Row>
            <Col>
              <Small color={'dark'}>Contract No.</Small>
              <Small color={'dark'}>Latest Submission No.</Small>
              <Small color={'dark'}>Prev. Req. No.</Small>
              <Small color={'dark'}>Seq. No.</Small>
            </Col>
            <Col style={[styles.marginLeft(10)]}>
              <Small color={'dark'}>
                {!!rev ? `${contractNumber}${rev}` : `${contractNumber || ''}`}
              </Small>
              <Small color={'dark'}>
                {!!latestVersion ? latestVersion : '-'}
              </Small>
              <Small color={'dark'}>
                {!!lastRevision ? lastRevision : `-`}
              </Small>
              <Small color={'dark'}>
                {!!sequentialNumber
                  ? `${contractNumber}${String(sequentialNumber).padStart(
                      sequentialNumberLength,
                      '0'
                    )}`
                  : `-`}
              </Small>
            </Col>
            <Flex />
          </Row>
        </Col>
        <Col style={[styles.blockh]}>
          <ButtonGroup>
            <Button
              onPress={async () => {
                await createVision();
              }}
            >
              {!!allowDelete ? <Text>New</Text> : <Text>New Request</Text>}
            </Button>
            <Button
              color={'darken-0.25-primary'}
              onPress={async () => {
                await createVision(true);
              }}
            >
              <Icon name={'clone'} />
            </Button>
            {!!allowDelete && (
              <RevisionDeleteButton
                projectId={projectId}
                formId={id}
                revisionId={revisionId}
                onDelete={onRevisionDelete}
              />
            )}
          </ButtonGroup>
          <Flex />
          {!isLocked ? (
            <Button
              color={'dark'}
              onPress={async () => {
                try {
                  ActivityIndicator.show();
                  const res = await client.mutate({
                    mutation: FORM_REVISION_LOCK(revisionId)
                  });
                  console.log(res);
                } catch (e) {
                  console.log(e);
                  Alert.danger(errorParser(e));
                } finally {
                  ActivityIndicator.hide();
                }
              }}
            >
              <Icon name={'unlock-alt'} />
              <Text>Unlocked</Text>
            </Button>
          ) : !!isLockedByMe ? (
            <Button
              color={'danger'}
              onPress={async () => {
                try {
                  ActivityIndicator.show();
                  const res = await client.mutate({
                    mutation: FORM_REVISION_UNLOCK(revisionId)
                  });
                  console.log(res);
                } catch (e) {
                  console.log(e);
                  Alert.danger(errorParser(e));
                } finally {
                  ActivityIndicator.hide();
                }
              }}
            >
              <Icon size={13} name={'lock-alt'} />
              <Text>Locked</Text>
            </Button>
          ) : (
            <Button color={'danger'}>
              <Icon size={13} name={'lock-alt'} />
              <Text>Locked By {lockedBy.name || 'Unknown'}</Text>
            </Button>
          )}
          <Flex />
        </Col>
      </Row>
    </HasBackground>
  );

  async function createVision(clone = false) {
    try {
      await alert('', clone ? 'Clone Revision?' : 'New Form?');

      ActivityIndicator.show();
      console.log({
        projectFormId: id,
        copyRevision: !!clone ? revisionId : undefined
      });
      const {
        data: {
          formRevisionCreate: { id: newRevision }
        }
      } = await client.mutate({
        mutation: FORM_REVISION_CREATE,
        variables: {
          projectFormId: id,
          copyRevision: !!clone ? revisionId : undefined
        }
      });
      onRevisionCreate(newRevision);
      Alert.success('Revision Added');
    } catch (e) {
      console.log(e);
      Alert.danger(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  }
};
