import React from 'react';
import { FormGroup, Label, Small } from '../../rnbc/components/form';
import { Row, Col } from '../../rnbc/components/layout';
import Icon from '../../rnbc/icon';
import Text from '../../rnbc/components/text';
import Button from '../../rnbc/components/button';
import { Image, TouchableOpacity } from '../../plugins';
import moment from 'moment';
import styles from '../../rnbc/styles';
import FieldLoading from './fieldLoading';
import { preferDateFormat } from '../shared/isTC';
import FieldUserGroupPositionsRemark from './FieldUserGroupPositionsRemark';

export default ({
  label,
  disabled,
  chopped,
  image,
  required,
  onChange = _ => _,
  updatedAt = '2019-10-08T10:42:52.688Z',
  description,
  _sync,
  userGroup,
  userPositions
}) => {
  return (
    <FormGroup>
      {!!_sync && (
        <FieldLoading style={[styles.absolute({ top: -5, right: 0 })]} />
      )}
      {!!label && (
        <Row fy={'center'}>
          <Label style={[styles.marginY(5, !!description ? 0 : 5)]}>
            {label} <Text color={'danger'}>{!!required && '**'}</Text>
          </Label>
          <FieldUserGroupPositionsRemark
            userGroup={userGroup}
            userPositions={userPositions}
          />
        </Row>
      )}
      {!!description && (
        <Small style={[styles.marginBottom(5)]}>{description}</Small>
      )}
      <Row fy={'center'}>
        <TouchableOpacity
          activeOpacity={disabled || chopped ? 1 : 0.5}
          onPress={!disabled && (() => !chopped && onChange(true))}
          style={[styles.marginTop(10), { width: 100, height: 100 }]}
        >
          {!!chopped && !!image ? (
            <Image
              source={{
                uri: image
              }}
              style={[styles.fill]}
            />
          ) : (
            <Row
              fy={'center'}
              fx={'center'}
              style={[
                styles.border(1),
                styles.rounded(50),
                styles.fill,
                !!chopped && { borderColor: styles.getColorCode('danger') }
              ]}
            >
              <Icon
                name={'stamp'}
                color={!!chopped ? 'danger' : 'border'}
                size={styles.h1.fontSize}
              />
            </Row>
          )}
        </TouchableOpacity>
        {!!chopped && !!updatedAt && (
          <Row fy={'center'} style={[styles.marginLeft(10)]}>
            <Col>
              <Text>RECEIVED</Text>
              <Text style={[{ fontSize: 14 }]}>
                {moment(updatedAt).format(`${preferDateFormat} hh:mm A`)}
              </Text>
            </Col>
            {!disabled && (
              <Button
                color={'danger'}
                transparent
                style={[styles.marginLeft(10)]}
                onPress={() => {
                  !!chopped && onChange(false);
                }}
              >
                <Icon name={'stamp'} size={14} style={[styles.marginLeft(5)]} />
                <Icon
                  name={'ban'}
                  size={28}
                  style={[styles.marginX(-14 - 7, 0)]}
                />
              </Button>
            )}
          </Row>
        )}
      </Row>
    </FormGroup>
  );
};
