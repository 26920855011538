import React, { Component, Fragment } from 'react';
import { Col } from '../../../rnbc/components/layout';
import styles from '../../../rnbc/styles';
import {
  ReactApollo,
  KeyboardAwareScrollView,
  StatusBar
} from '../../../plugins';
import Button from '../../../rnbc/components/button';
import Text from '../../../rnbc/components/text';
import Icon from '../../../rnbc/icon';
import NavHeader from '../../../rnbc/components/navHeader';
import { BottomPadder } from '../../../rnbc/components/iphonePadder';
import Loading from '../../../rnbc/components/loading';
import TabContainer from '../../../rnbc/components/tabContainer';
import Formilize from '../../../rnbc/components/formilize';
import Form, { FormGroup, Input, Label } from '../../../rnbc/components/form';
import { GET_TEMPLATE } from './_gql';
import Stage from './_stage';
import Alert from '../../../rnbc/components/alert';
import { errorParser } from '../../shared/errorParser';
import moment from 'moment';
import Dropdown from '../../../rnbc/components/dropdown';

export default class extends Component {
  onSubmit = async ({ view, ...values }) => {
    const { history, match: { params } = {} } = this.props;
    const { projectId, formId } = params || {};
    try {
      const input = Object.keys(values)
        .map(key => {
          if (!values[key]) return null;
          const KValue = values[key] || {};
          const value = {};

          /* Handle since until input */
          Handle_Since_Until: {
            const { since, until } = KValue;
            if (!!since || !!until) {
              let mergedSince = mergeDateTimeArray2String(since);
              let mergedUntil = mergeDateTimeArray2String(until);

              !!mergedSince && (value.since = mergedSince);
              !!mergedUntil && (value.until = mergedUntil);

              if (!!value.since && !value.until)
                value.until = moment().toISOString();
              if (!!value.until && !value.since)
                value.since = '1990-12-31T16:00:00.000Z';
            }
          }
          Handle_Value: {
            const { input } = KValue;
            if (input !== undefined && input !== null) value.input = input;
          }

          if (Object.keys(value).length === 0) return null;

          return {
            id: key,
            ...value
          };
          function mergeDateTimeArray2String([date, time]) {
            if (!date) return null;
            const datetime = moment(date);
            let input = '';
            if (!!time) {
              datetime.hour(moment(time).hour());
              datetime.minute(moment(time).minute());
              datetime.second(0);
              input = datetime.toISOString();
            } else {
              input = datetime.format('YYYY-MM-DD');
            }
            return input;
          }
        })
        .filter(_ => _);

      if (input.length === 0) throw new Error('Require at least one field');
      history.push({
        pathname: `/SearchResults/${projectId}/${formId}/${JSON.stringify({
          view: !!view ? view.id : undefined,
          filter: input
        })}`
      });
    } catch (e) {
      Alert.danger(errorParser(e));
    }
  };
  render() {
    const {
      history,
      match: { params } = {},
      location: { state: { view } = {} } = {}
    } = this.props;
    const { projectId, formId } = params || {};

    return (
      <Formilize onSubmit={this.onSubmit} initialValues={{ view }}>
        {({ values, setFieldValue, handleSubmit }) => (
          <Col style={[styles.blockh]}>
            <StatusBar barStyle={'dark-content'} />
            <NavHeader
              back
              history={history}
              color={'white'}
              title={'Search'}
              rightComponent={
                <Button transparent onPress={handleSubmit}>
                  <Icon name={'search'} />
                </Button>
              }
            />
            <KeyboardAwareScrollView style={[styles.paddingY(20, 0, 10)]}>
              <Form style={[styles.padding(0, 10)]}>
                <FormGroup>
                  <Label>Search By Revision Number</Label>
                  <Input
                    value={(values.revision || {}).input}
                    placeholder={'54A etc.'}
                    onChangeText={input => setFieldValue('revision', { input })}
                  />
                </FormGroup>
              </Form>
              <ReactApollo.Query query={GET_TEMPLATE(projectId, formId)}>
                {({ loading, data }) => {
                  if (loading) return <Loading />;
                  const { me } = data || {},
                    project =
                      (me || {})[`p_${projectId.replace(/-/g, '_')}`] || {},
                    form = project[`f_${formId.replace(/-/g, '_')}`] || {},
                    { template, fieldSettings } = form || {},
                    { stages, views } = template || {};
                  const settings = (fieldSettings || []).map(json =>
                    JSON.parse(json)
                  );
                  return (
                    <Fragment>
                      <FormGroup style={[styles.padding(0, 10)]}>
                        <Label>View</Label>
                        <Dropdown
                          color={'white'}
                          style={[styles.border(1), styles.rounded(5)]}
                          title={(values.view || {}).label || ''}
                        >
                          {(views || []).map((view, i) => (
                            <Button
                              key={i}
                              onPress={() => {
                                setFieldValue('view', view);
                              }}
                              transparent
                              style={[
                                styles.margin(0),
                                styles.rounded(0),
                                { justifyContent: 'flex-start' }
                              ]}
                            >
                              <Text>{view.label}</Text>
                            </Button>
                          ))}
                        </Dropdown>
                      </FormGroup>
                      <TabContainer
                        defaultTab={0}
                        tabs={(stages || []).map((stage, i) => ({
                          title: stage.label,
                          component: (
                            <Stage
                              values={values}
                              setFieldValue={setFieldValue}
                              fields={stage.fields || []}
                              settings={(settings || []).reduce(
                                (reducer, setting) => {
                                  reducer[setting.fieldId] = setting.data;
                                  return reducer;
                                },
                                {}
                              )}
                            />
                          )
                        }))}
                      />
                    </Fragment>
                  );
                }}
              </ReactApollo.Query>
              <BottomPadder additionPadder={100} />
            </KeyboardAwareScrollView>
          </Col>
        )}
      </Formilize>
    );
  }
}
