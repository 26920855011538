import React, { Component } from 'react';
import { StatusBar } from '../../../plugins';
import styles from '../../../rnbc/styles';
import { Col, Flex, Row } from '../../../rnbc/components/layout';
import NavHeader from '../../../rnbc/components/navHeader';
import RevisionRowsTable from './_revisionRowsTable';
import Pager from '../Revisions/_pager';
import JSONParser from '../../shared/JSONParser';
import ActivityIndicator from '../../../rnbc/components/activityIndicator';
import Alert from '../../../rnbc/components/alert';
import { errorParser } from '../../shared/errorParser';
import { FORM_REVISION_GENCSV, FORM_REVISION_CREATE } from './_gql';
import { client } from '../../shared/apollo';
import { alert } from '../../shared/popup';

export default class extends Component {
  constructor(...args) {
    super(...args);
    const { match: { params } = {} } = this.props,
      { cursors = '' } = params || {};
    this.state = {
      key_main: 0,
      cursors: cursors.split(',').filter(_ => _),
      nextCursor: undefined,
      totalCount: 0,
      currentCount: 0
    };
  }
  onConnection = ({ nextCursor, totalCount, nodes }) => {
    const currentCount = (nodes || []).length;
    if (totalCount !== this.state.totalCount) this.setState({ totalCount });
    if (currentCount !== this.state.currentCount)
      this.setState({ currentCount });
    if (
      nextCursor !== this.state.nextCursor &&
      !~this.state.cursors.indexOf(nextCursor)
    )
      this.setState({ nextCursor });
  };
  onRefreshPress = () => {
    this.setState({ key_main: Math.random() });
  };
  onResubmittingRevisionPress = id => {
    const { history, match: { params } = {} } = this.props,
      { formId, projectId } = params || {};

    history.push({
      pathname: `/Revision/${projectId}/${formId}/${id}`,
      state: {
        title: ''
      }
    });
  };
  onExportPress = async () => {
    const { match: { params } = {} } = this.props,
      { formId, input } = params || {};
    const { filter, view } = JSONParser(input, {});

    try {
      ActivityIndicator.show();
      await client.mutate({
        mutation: FORM_REVISION_GENCSV,
        variables: {
          formId,
          templateViewId: view,
          filter
        }
      });
      Alert.info('Exported file will be sent to your email.');
    } catch (e) {
      Alert.danger(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  };
  onResubmitPress = async revision => {
    const { match: { params } = {} } = this.props,
      { formId } = params || {};
    try {
      await alert('', 'Create Resubmit Form?');
      ActivityIndicator.show();
      const {
        data: { formRevisionCreate }
      } = await client.mutate({
        mutation: FORM_REVISION_CREATE,
        variables: {
          projectFormId: formId,
          sourceRevision: revision.id,
          copyRevision: revision.id
        }
      });
      Alert.success('Resubmit Added');
      console.log(formRevisionCreate);
      this.onResultPress(formRevisionCreate);
    } catch (e) {
      console.log(e);
      Alert.danger(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  };
  onResultPress = ({ id = '' }) => {
    const { history, match: { params } = {} } = this.props,
      { projectId, formId } = params || {};
    history.push({
      pathname: `/Revision/${projectId}/${formId}/${id}`,
      state: {
        title: ''
      }
    });
  };
  onNextPress = () => {
    const { history, match: { params } = {} } = this.props,
      { projectId, formId, input } = params || {};
    const { nextCursor, cursors } = this.state;

    if (!!nextCursor) {
      NavHeader.resetLastBackReferrer();
      const _cursors = cursors.concat(nextCursor);
      history.replace({
        pathname: `/SearchResults/${projectId}/${formId}/${input}/${_cursors.join(
          ','
        )}`
      });
      this.setState({
        nextCursor: undefined,
        cursors: _cursors
      });
    }
  };
  onPrevPress = () => {
    const { history, match: { params } = {} } = this.props,
      { projectId, formId, input } = params || {};
    const { cursors } = this.state;
    if (cursors.length > 0) {
      NavHeader.resetLastBackReferrer();
      const nextCursor = cursors.pop();
      history.replace({
        pathname: `/SearchResults/${projectId}/${formId}/${input}/${cursors.join(
          ','
        )}`
      });
      this.setState({
        nextCursor,
        cursors: [...cursors]
      });
    }
  };
  onFirstPress = () => {
    const { history, match: { params } = {} } = this.props,
      { projectId, formId, input } = params || {};
    const { cursors } = this.state;
    if (cursors.length > 0) {
      NavHeader.resetLastBackReferrer();
      const nextCursor = cursors[0];
      history.replace({
        pathname: `/SearchResults/${projectId}/${formId}/${input}`
      });
      this.setState({
        nextCursor,
        cursors: []
      });
    }
  };
  render() {
    const { history, match: { params } = {} } = this.props,
      { cursors = [], nextCursor, totalCount, currentCount } = this.state,
      { projectId, formId, input } = params || {};
    const { filter, view } = JSONParser(input, {});

    return (
      <Col style={[styles.blockh]}>
        <StatusBar barStyle={'dark-content'} />
        <NavHeader
          back
          history={history}
          color={'white'}
          title={`${totalCount === undefined ? '...' : totalCount} Result(s)`}
        />
        <Row style={[styles.header, styles.marginRight(10)]} fx={'flex-end'}>
          <Pager
            color={'dark'}
            style={[styles.flex(1)]}
            cursors={cursors}
            nextCursor={nextCursor}
            totalCount={totalCount}
            currentCount={currentCount}
            onNextPress={this.onNextPress}
            onPrevPress={this.onPrevPress}
            onFirstPress={this.onFirstPress}
          />
        </Row>
        <Flex>
          <RevisionRowsTable
            onExportData={this.onExportPress}
            cursor={cursors[cursors.length - 1]}
            projectId={projectId}
            formId={formId}
            view={view}
            filter={filter}
            onEditPress={this.onResultPress}
            onConnection={this.onConnection}
            onResubmitPress={this.onResubmitPress}
            onResubmittingRevisionPress={this.onResubmittingRevisionPress}
          />
        </Flex>
      </Col>
    );
  }
}
