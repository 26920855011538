import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../rnbc/icon';
import Button from '../../rnbc/components/button';
import { Modal, View, Text } from '../../plugins';
import moment from 'moment';
import styles from '../../rnbc/styles';
import FullWrapper from '../../rnbc/components/fullWrapper';
import { TopPadder } from '../../rnbc/components/iphonePadder';
import DoneBar from '../../rnbc/components/form/modalized/doneBar';
import { Col } from '../../rnbc/components/layout';
import ButtonFileField from './buttonFileField';

export default class ExportedFileField extends Component {
  state = {
    show: false
  };
  onPrintPress = () => {
    const { exportedFile } = this.props;
    if (!!exportedFile) this.setState({ show: true });
  };
  onRequestClose = () => {
    this.setState({ show: false });
  };
  onViewLastPress = () => {
    this.setState({ show: false });
    this.preview && this.preview.onDisplayerPress();
  };
  render() {
    const { color, exportedFile, fileUpdatedAt } = this.props;

    const hasLatestFile =
      !!exportedFile &&
      moment(exportedFile.createdAt).isAfter(moment(fileUpdatedAt));

    if (hasLatestFile)
      return (
        <ButtonFileField
          color={color}
          value={
            !!exportedFile
              ? { ...exportedFile, uri: exportedFile.downloadUrl }
              : undefined
          }
        />
      );
    return (
      <Fragment>
        <View style={[{ opacity: 0.25 }]}>
          <Button transparent color={color} onPress={this.onPrintPress}>
            <Icon name={'print'} />
          </Button>
        </View>
        {this.renderModal()}
        <View
          style={[
            styles.absolute({
              top: -styles.vh(1).height,
              left: -styles.vw(1).width
            })
          ]}
        >
          <ButtonFileField
            ref={ref => (this.preview = ref)}
            color={color}
            value={
              !!exportedFile
                ? { ...exportedFile, uri: exportedFile.downloadUrl }
                : undefined
            }
          />
        </View>
      </Fragment>
    );
  }
  renderModal() {
    const { show } = this.state;
    if (!show) return null;
    return (
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        visible={show}
        transparent={false}
        onRequestClose={this.onRequestClose}
      >
        <FullWrapper
          style={[
            styles.column,
            styles.backgroundColor('white'),
            { position: 'relative' }
          ]}
        >
          {this.renderDoneBar()}
          {this.renderLastZone()}
        </FullWrapper>
      </Modal>
    );
  }
  renderDoneBar() {
    return (
      <Fragment>
        <TopPadder />
        <DoneBar
          leftWidget={
            <Button transparent onPress={this.onRequestClose}>
              <Icon name={'arrow-left'} />
            </Button>
          }
        />
      </Fragment>
    );
  }
  renderLastZone() {
    return (
      <Col
        fx={'center'}
        fy={'center'}
        style={[styles.flex(1), styles.backgroundColor('white')]}
      >
        <Icon name={'file'} size={64} />
        <Text style={[styles.marginY(10)]}>
          New PDF is not ready yet, do you want to view the last one?
        </Text>
        <Button transparent onPress={this.onViewLastPress}>
          <Text>View Last File</Text>
        </Button>
      </Col>
    );
  }
}
ExportedFileField.propTypes = {
  color: PropTypes.string,
  exportedFile: PropTypes.any,
  fileUpdatedAt: PropTypes.string
};
ExportedFileField.defaultProps = {
  color: 'light'
};
