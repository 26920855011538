import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../styles';
import Icon from '../../../icon';
import {
  DocumentPicker,
  Image,
  ImagePicker,
  PDFView,
  TouchableOpacity,
  Modal,
  Share,
  View,
  ScrollView,
  FetchBlob,
  StatusBar,
  OS
} from '../../../../plugins';
import Alert from '../../alert';
import Button from '../../button';
import Loading from '../../loading';
import Text from '../../text';
import H3 from '../../h3';
import DoneBar from '../modalized/doneBar';
import { Row, Col } from '../../layout';
import ZoomableView2 from '../../zoomableView2';
import ModalizedInput from '../modalized/input';
import { errorParser } from '../../../../wrapper/shared/errorParser';
import ActionSheet from '../../actionSheet';
import MimeTypeMap from '../mimeTypeMap';
import { BottomPadder, TopPadder } from '../../iphonePadder';
import FullWrapper from '../../fullWrapper';
import moment from 'moment';
import bytes from 'bytes';
const ImgGradient = require('../../../image/gradient.png');

export default class FileInputS3_2 extends Component {
  state = {
    loading: false,
    show: false,
    showDescriptionInputModal: false
  };
  unlinkPaths = [];
  async componentWillUnmount() {
    for (const path of this.unlinkPaths) {
      try {
        await FetchBlob.fs.unlink(path);
        console.log('unlink', path);
      } catch (e) {}
    }
    this.unlinkPaths = [];
  }

  parseFile = file => {
    let url = this.getUrl(file);
    if (!file || !url)
      return {
        isValid: false
      };

    let name = file.name || url.split('/').pop();
    let extension = this.parseExtension(file.name || url);
    let mimeType = file.type || this.parseMimeType(extension);
    let isFilePath = /^content/.test(url);

    return {
      isValid: true,
      url,
      name,
      extension,
      mimeType,
      isFilePath
    };
  };
  getUrl = file => {
    try {
      const { key, region, bucket, url, uri } = file || {};
      if (!!url) return url.replace(/%2F/g, '/') || '';
      else if (!!uri) return uri.replace(/%2F/g, '/') || '';
      else if (!!bucket && !!region && !!key)
        return `https://${bucket}.s3-${region}.amazonaws.com/${key.replace(
          /%2F/g,
          '/'
        )}`;
    } catch (e) {}
    return '';
  };
  parseMimeType = extension => {
    return MimeTypeMap[extension] || `application/${extension}`;
  };
  parseExtension = input => {
    return input
      .split('.')
      .pop()
      .toLowerCase();
  };
  onRequestClose = () => {
    this.setState({ show: false });
  };
  onIOSSharePress = async () => {
    const { value } = this.props;
    let { url, isFilePath, name, mimeType } = this.parseFile(value);
    try {
      this.setState({ loading: true });
      if (!isFilePath) {
        const res = await FetchBlob.config({
          path: `${FetchBlob.fs.dirs.CacheDir}/${name}`
        }).fetch('GET', url);
        this.onRequestClose();
        await new Promise(resolve => setTimeout(resolve, 100));
        const path = await res.path();
        await FetchBlob.ios.previewDocument(path);
        this.unlinkPaths.push(path);
      } else {
        const { path } = await FetchBlob.fs.stat(url);
        await Share.open({
          url: path,
          type: mimeType,
          showAppsToView: true,
          failOnCancel: false
        });
      }
    } catch (e) {
      this.onRequestClose();
      console.log(e);
      Alert.danger(errorParser(e));
    } finally {
      this.setState({ loading: false });
    }
  };
  onAndroidSharePress = async () => {
    const { value } = this.props;
    let { url, isFilePath, name, mimeType } = this.parseFile(value);
    try {
      this.setState({ loading: true });
      if (!isFilePath) {
        const res = await FetchBlob.config({
          path: `${FetchBlob.fs.dirs.CacheDir}/${name}`
        }).fetch('GET', url);
        await Share.open({
          url: `file://${res.path()}`,
          type: mimeType,
          showAppsToView: true,
          failOnCancel: false
        });
      } else {
        const { path } = await FetchBlob.fs.stat(url);
        await Share.open({
          url: path,
          type: mimeType,
          showAppsToView: true,
          failOnCancel: false
        });
      }
    } catch (e) {
      console.log(e);
      Alert.danger(errorParser(e));
    } finally {
      this.setState({ loading: false });
    }
  };
  onRemovePress = async () => {
    const { onChange } = this.props;
    this.setState({ show: false });
    onChange(undefined);
  };
  onDisplayerPress = () => {
    const { value, disabled } = this.props;
    if (!!value) {
      this.setState({ show: true });
    } else {
      !disabled && this.pickFile();
    }
  };
  pickFile = async () => {
    const { imageOnly, maxSize, onFileSelect, multiple } = this.props;
    const pickFromCamera = async () => {
      const image = await ImagePicker.openCamera({});
      return []
        .concat(image)
        .filter(_ => _)
        .map(image => ({
          size: image.size,
          uri: image.path,
          name: image.path.split('/').pop(),
          type: image.mime
        }));
    };
    const pickFromGallery = async () => {
      const images = await ImagePicker.openPicker({ multiple });
      return []
        .concat(images)
        .filter(_ => _)
        .map(image => ({
          size: image.size,
          uri: image.path,
          name: image.path.split('/').pop(),
          type: image.mime
        }));
    };
    const pickFromFileManager = async () => {
      const files = await DocumentPicker[multiple ? 'pickMultiple' : 'pick']({
        readContent: true
      });
      return [].concat(files).filter(_ => _);
    };
    const pickFromOthers = async (
      fromCamera = true,
      fromGallery = true,
      fromManager = true
    ) => {
      try {
        const options = [
          fromCamera && { label: 'Launch Camera', func: pickFromCamera },
          fromGallery && {
            label: 'Choose from Gallery...',
            func: pickFromGallery
          },
          fromManager && {
            label: 'Choose from File Manager...',
            func: pickFromFileManager
          }
        ].filter(_ => _);
        const selection = await ActionSheet.execute({
          title: 'Select Avatar',
          options: options.map(({ label }) => label)
        });
        return !!options[selection] && (await options[selection].func());
      } catch (e) {
        !!e && !!e.message && Alert.danger(e.message);
      }
    };
    try {
      const files = imageOnly
        ? await pickFromOthers(true, true, false)
        : await pickFromOthers();
      if (!files) return;
      for (const file of files) {
        const size = file.size || 0;
        if (size > maxSize)
          throw new Error(
            `Files larger than ${bytes(
              maxSize
            )} is not allowed, selected file is ${bytes(size)}.`
          );
      }
      onFileSelect(
        files.map(file => ({
          uri: file.uri,
          name: file.name,
          type: file.type
        }))
      );
    } catch (e) {
      Alert.danger(e.message);
    }
  };
  render() {
    const { style, value, disabled, imageOnly } = this.props;
    const { isValid } = this.parseFile(value);

    return (
      <Fragment>
        <TouchableOpacity
          activeOpacity={!disabled ? 0.5 : 1}
          onPress={this.onDisplayerPress}
          style={[
            styles.center,
            styles.border(1),
            styles.backgroundColor(!disabled ? 'white' : 'bg'),
            styles.rounded(5),
            {
              position: 'relative',
              aspectRatio: 2,
              minWidth: 200,
              minHeight: 100
            }
          ].concat(style)}
        >
          {!!isValid && this.renderFileDisplayer()}
          {!isValid && !disabled && (
            <Icon
              name={!!imageOnly ? 'images' : 'file-plus'}
              size={48}
              color={'dark-bg'}
            />
          )}
          {!isValid && !!disabled && <H3 color={'border'}>No File</H3>}

          {this.renderDescription()}
        </TouchableOpacity>
        {this.renderModal()}
      </Fragment>
    );
  }
  renderFileDisplayer() {
    const { value } = this.props;
    const { name, url, extension, isFilePath } = this.parseFile(value);

    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return (
          <Image
            resizeMode={'cover'}
            style={[styles.fill]}
            source={{ uri: url }}
          />
        );
      case 'pdf':
        return (
          <PDFView
            key={url}
            style={[styles.fill, styles.flex(1)]}
            resource={url}
            resourceType={isFilePath ? 'file' : 'url'}
          />
        );
      default:
        return (
          <Row fy={'center'}>
            <Icon name={'file'} style={[styles.marginRight(10)]} />
            <Text>{name}</Text>
          </Row>
        );
    }
    return null;
  }
  renderDescription() {
    const { value, hasDescription, timeFormat } = this.props,
      { description, createdAt } = value || {};
    if ((!description || !hasDescription) && !createdAt) return null;
    return (
      <Col style={[styles.absolute({ bottom: 0, left: 0, right: 0 })]}>
        <Image
          resizeMode={'stretch'}
          source={ImgGradient}
          style={[styles.block, { height: 25, opacity: 0.5 }]}
        />
        <Col
          style={[
            styles.padding(0, 10, 10),
            styles.backgroundColor('rgba(0,0,0,0.5)')
          ]}
        >
          {!!hasDescription && !!description && (
            <Text color={'light'} numberOfLines={3}>
              {description}
            </Text>
          )}
          <Text color={'light'} style={[{ textAlign: 'right' }]}>
            {moment(createdAt).format(timeFormat)}
          </Text>
        </Col>
      </Col>
    );
  }
  renderModal() {
    const { show } = this.state;
    if (!show) return null;
    return (
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        visible={show}
        transparent={false}
        onRequestClose={this.onRequestClose}
      >
        <StatusBar barStyle={'dark-content'} />
        <FullWrapper
          style={[
            styles.column,
            styles.backgroundColor('white'),
            { position: 'relative' }
          ]}
        >
          <TopPadder style={[styles.backgroundColor('white'), { zIndex: 1 }]} />
          {this.renderDoneBar()}
          {this.renderPreviewZone()}
          {this.renderDescriptionInput()}
          {this.renderLoading()}
        </FullWrapper>
      </Modal>
    );
  }
  renderDoneBar() {
    const { disabled } = this.props;
    return (
      <DoneBar
        style={[{ zIndex: 1 }]}
        leftWidget={
          <Button transparent onPress={this.onRequestClose}>
            <Icon name={'arrow-left'} />
          </Button>
        }
        widget={
          <Fragment>
            {!disabled && (
              <Button transparent color={'danger'} onPress={this.onRemovePress}>
                <Icon name={'trash-alt'} />
              </Button>
            )}
            <Button
              transparent
              onPress={
                OS === 'ios' ? this.onIOSSharePress : this.onAndroidSharePress
              }
            >
              <Icon name={'share-alt'} />
            </Button>
          </Fragment>
        }
      />
    );
  }
  renderPreviewZone() {
    const { value } = this.props;
    const { url, isFilePath, extension } = this.parseFile(value);
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return (
          <ZoomableView2
            bindToBorders={false}
            controls={false}
            style={[styles.flex(1)]}
          >
            <TouchableOpacity
              activeOpacity={1}
              style={[styles.vw(1), styles.vh(1)]}
            >
              <Image
                resizeMode={'contain'}
                style={[styles.fill]}
                source={{ uri: url }}
              />
            </TouchableOpacity>
          </ZoomableView2>
        );
      case 'pdf':
        return (
          <PDFView
            style={[styles.flex(1)]}
            resource={url}
            resourceType={isFilePath ? 'file' : 'url'}
          />
        );
      default:
        return (
          <ZoomableView2
            bindToBorders={false}
            controls={false}
            style={[styles.flex(1)]}
          >
            <H3 color={'border'}>Nothing to Preview</H3>
          </ZoomableView2>
        );
    }
  }
  renderDescriptionInput() {
    const { value, onChange, disabled, hasDescription } = this.props,
      { description } = value || {};
    if (!hasDescription) return null;
    const lines = (description || '').split('\n').length;
    return (
      <ScrollView
        style={[
          styles.border(1, 0, 0),
          styles.backgroundColor('white'),
          { maxHeight: '25%', height: lines * 25 }
        ]}
      >
        <ModalizedInput
          disabled={disabled}
          multiline={true}
          style={[styles.border(0), styles.rounded(0)]}
          value={description}
          onChange={description => {
            onChange({ ...value, description });
          }}
          multiNumberOfLines={3}
          placeholder={'Description...'}
        />
        <BottomPadder />
      </ScrollView>
    );
  }
  renderLoading() {
    const { loading } = this.state;
    if (!loading) return null;
    return (
      <Col
        fy={'center'}
        fx={'center'}
        style={[styles.absolute({ top: 0, left: 0, right: 0, bottom: 0 })]}
      >
        <View
          style={[
            styles.padding(20),
            styles.backgroundColor('rgba(0,0,0,0.5)'),
            styles.rounded(5)
          ]}
        >
          <Loading size={32} color={'white'} />
        </View>
      </Col>
    );
  }
}
FileInputS3_2.propTypes = {
  value:
    PropTypes.any /* S3Object { key, bucket, region, description, createdAt }  or  { url }*/,
  onChange: PropTypes.func,
  onFileSelect: PropTypes.func,
  style: PropTypes.any,
  disabled: PropTypes.bool,
  hasDescription: PropTypes.bool,
  imageOnly: PropTypes.bool,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  timeFormat: PropTypes.string
};
FileInputS3_2.defaultProps = {
  onChange: _ => _,
  onFileSelect: _ => _,
  imageOnly: false,
  hasDescription: true,
  maxSize: 104857600 /* 100 GB */,
  multiple: false,
  timeFormat: 'DD-MMM-YYYY HH:mm'
};
