import React from 'react';
import {
  FormGroup,
  Label,
  ModalizedInputSelect,
  Small
} from '../../rnbc/components/form';
import moment from 'moment';
import Text from '../../rnbc/components/text';
import styles from '../../rnbc/styles';
import FieldLoading from './fieldLoading';
import { Row } from '../../rnbc/components/layout';
import FieldUserGroupPositionsRemark from './FieldUserGroupPositionsRemark';
import { preferDateFormat } from '../shared/isTC';

export default ({
  disabled,
  label,
  multiline = false,
  text,
  onChange,
  required,
  updatedBy,
  updatedAt,
  values = [],
  _sync,
  description,
  userGroup,
  userPositions
}) => (
  <FormGroup>
    {!!_sync && (
      <FieldLoading style={[styles.absolute({ top: -5, right: 0 })]} />
    )}
    {!!label && (
      <Row fy={'center'}>
        <Label style={[styles.marginY(5, !!description ? 0 : 5)]}>
          {label} {!!required && <Text color={'danger'}>**</Text>}
        </Label>
        <FieldUserGroupPositionsRemark
          userGroup={userGroup}
          userPositions={userPositions}
        />
      </Row>
    )}
    {!!description && (
      <Small style={[styles.marginBottom(5)]}>{description}</Small>
    )}
    <ModalizedInputSelect
      disabled={disabled}
      issuer={!!updatedBy ? updatedBy.name : ''}
      entitle={!!updatedBy ? updatedBy.position : ''}
      issuedAt={
        !!updatedBy && !!updatedAt
          ? moment(updatedAt).format(`${preferDateFormat} HH:mm`)
          : ''
      }
      value={text}
      items={values}
      multiline={multiline}
      onChangeText={onChange}
    />
  </FormGroup>
);
