import { variations as approvalVariations } from '../../component/approvalField';
import moment from 'moment';
import { preferDateFormat } from '../../shared/isTC';

export default (field, _row, i, _original) => {
  const { __typename } = field;
  switch (__typename) {
    case 'UserField':
      const users = field.users || [];
      if (users.length > 0) {
        if (users.length > 1)
          return { label: `${users[0].name} (${users.length} users)` };
        return { label: users[0].name, noted: field.noted };
      }
      return {};
    case 'ApprovalField':
      const variations = approvalVariations[field.numberOfButton || 4];
      if (field.status === 'REJECTED') {
        _row._color = 'danger';
        if (!_row.hasResubmittingRevision) _row._hasResubmitButton = true;
      }
      return (variations.find(({ value }) => value === field.status) || {})
        .label;
    case 'LocationField':
      const { x, y, radius, latitude, longitude, altitude, ...others } =
        field.location || {};
      return Object.keys(others)
        .map(key => (!~['__typename'].indexOf(key) ? others[key] : ''))
        .filter(_ => _)
        .join(' ');
    case 'FileField':
      const files = field.files || [];
      if (files.length > 0) {
        if (files.length > 1) return `${files.length} files`;
        return files[0].key;
      }
      return '';
    case 'SignatureField':
      return !!field.updatedBy && !!field.updatedBy.name
        ? field.updatedBy.name
        : '';
    case 'DateTimeField':
      if (!!field.dateTime) {
        const isFirst =
          _original.findIndex(field => field.__typename === 'DateTimeField') ===
          i;

        return {
          late:
            _row.firstSubmittedAt &&
            field.dateTime &&
            isFirst &&
            moment(_row.firstSubmittedAt).isAfter(field.dateTime),
          display: moment(field.dateTime).format(`${preferDateFormat} hh:mm A`)
        };
      }
      return '';
    case 'TextField':
      return field.text;
    case 'EnumField':
      return field.value;
    case 'ChopField':
      if (!!field.chopped && !!field.updatedAt)
        return moment(field.updatedAt).format(`${preferDateFormat} hh:mm A`);
      return '';
    case 'WithdrawButton':
      if (!!_row.withdrawnBy) {
        if (!!_row.finalized)
          _row._textStyle = { textDecorationLine: 'line-through' };
        return _row.withdrawnBy.name;
      }
      return '';
    case 'SubmitButton':
      if (!!field.updatedAt)
        return moment(field.updatedAt).format(`${preferDateFormat} hh:mm A`);
      return '';
  }
  return field.value;
};
