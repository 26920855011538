import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FormStageMaker from '../../component/formStageMaker';
import FormSyncTaskWorker from '../../component/formSyncTaskWorker';
import {
  FORM_REVISION_SUBMIT,
  FORM_REVISION_WITHDRAWAL_REQUEST,
  FORM_REVISION_WITHDRAWAL_ACCEPT,
  FORM_REVISION_WITHDRAWAL_REJECT,
  FORM_REVISION_NOTIFY
} from './_gql';
import { client } from '../../shared/apollo';
import Alert from '../../../rnbc/components/alert';
import { errorParser } from '../../shared/errorParser';
import ActivityIndicator from '../../../rnbc/components/activityIndicator';
import moment from 'moment';

export default class Stage extends Component {
  onFieldUpdate = async (key, value) => {
    const { revisionId, stage } = this.props;
    const fixedFieldId = key.replace(new RegExp(`^(.*)-${revisionId}$`), '$1');
    const fixedStageId = stage.id.replace(
      new RegExp(`^(.*)-${revisionId}$`),
      '$1'
    );
    const data = JSON.stringify(this.getFieldData(value));
    FormSyncTaskWorker.addTask(
      {
        revisionId,
        stageId: fixedStageId,
        fields: [
          {
            fieldId: fixedFieldId,
            data
          }
        ]
      },
      1000
    );
  };
  getFieldData = value => {
    const { __typename } = value;
    switch (__typename) {
      case 'NotifyField':
        return {
          message: value.message,
          users: (value.users || []).map(user => user.email)
        };
      case 'EnumField':
        return { value: value.value };
      case 'TextField':
        return { text: value.text };
      case 'SignatureField':
        return { signature: value.signature };
      case 'FileField':
        return { files: value.files };
      case 'ApprovalField':
        return { status: value.status };
      case 'ChopField':
        return { chopped: value.chopped };
      case 'LocationField':
        const location = Object.keys(value.location).reduce((reducer, key) => {
          if (!!value.location[key]) reducer[key] = value.location[key];
          return reducer;
        }, {});
        return { location };
      case 'UserField':
        return {
          users: (value.users || [])
            .map(user => (user || {}).email)
            .filter(_ => _),
          noted: value.noted
        };
      case 'DateTimeField':
        return { dateTime: value.dateTime, updatedAt: moment().toISOString() };
    }
  };
  onNotify = async (_, field) => {
    const { revisionId } = this.props;
    try {
      ActivityIndicator.show();
      const fixedFieldId = field.id.replace(
        new RegExp(`^(.*)-${revisionId}$`),
        '$1'
      );
      console.log({
        notifyFieldId: fixedFieldId,
        revisionId
      });
      const res = await client.mutate({
        mutation: FORM_REVISION_NOTIFY,
        variables: {
          notifyFieldId: fixedFieldId,
          revisionId
        }
      });
      console.log(res);
      Alert.success('Notify Sent');
    } catch (e) {
      Alert.danger(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  };
  onSubmit = async (stage, field) => {
    const { revisionId } = this.props;
    const fixedStageId = stage.id.replace(
      new RegExp(`^(.*)-${revisionId}$`),
      '$1'
    );

    try {
      ActivityIndicator.show();
      if (FormSyncTaskWorker.getTasksCount() > 0)
        throw new Error('Form is Synchronizing');

      const fixedFieldId = field.id.replace(
        new RegExp(`^(.*)-${revisionId}$`),
        '$1'
      );
      console.log({
        submitButtonId: fixedFieldId,
        revisionId,
        stageId: fixedStageId
      });
      const res = await client.mutate({
        mutation: FORM_REVISION_SUBMIT(revisionId),
        variables: {
          submitButtonId: fixedFieldId,
          stageId: fixedStageId
        }
      });
      console.log(res);
      Alert.success('Submitted');
    } catch (e) {
      console.log(e);
      Alert.danger(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  };
  onWithdraw = async () => {
    const { revisionId } = this.props;
    try {
      ActivityIndicator.show();
      const res = await client.mutate({
        mutation: FORM_REVISION_WITHDRAWAL_REQUEST(revisionId)
      });
      console.log(res);
      Alert.success('Request Sent');
    } catch (e) {
      console.log(e);
      Alert.danger(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  };

  onWithdrawalApprove = async () => {
    const { revisionId } = this.props;
    try {
      ActivityIndicator.show();
      const res = await client.mutate({
        mutation: FORM_REVISION_WITHDRAWAL_ACCEPT(revisionId)
      });
      console.log(res);
      Alert.success('Approved');
    } catch (e) {
      console.log(e);
      Alert.danger(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  };

  onWithdrawalReject = async () => {
    const { revisionId } = this.props;
    try {
      ActivityIndicator.show();
      const res = await client.mutate({
        mutation: FORM_REVISION_WITHDRAWAL_REJECT(revisionId)
      });
      console.log(res);
      Alert.success('Rejected');
    } catch (e) {
      console.log(e);
      Alert.danger(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  };

  render() {
    const {
      me,
      dataset,
      withdrawnBy,
      lockedBy,
      projectId,
      formId,
      version,
      revisionId,
      finalized,
      stage = {}
    } = this.props;

    return (
      <Fragment>
        <FormStageMaker
          onUpdate={this.onFieldUpdate}
          onSubmit={field => this.onSubmit(stage, field)}
          onNotify={field => this.onNotify(stage, field)}
          onWithdraw={field => this.onWithdraw(stage, field)}
          onWithdrawalApprove={field => this.onWithdrawalApprove(stage, field)}
          onWithdrawalReject={field => this.onWithdrawalReject(stage, field)}
          me={me}
          dataset={dataset}
          lockedBy={lockedBy}
          withdrawnBy={withdrawnBy}
          {...stage}
          finalized={finalized}
          projectId={projectId}
          formId={formId}
          revisionId={revisionId}
          version={version}
          id={(stage.id || '').replace(
            new RegExp(`^(.*)-${revisionId}$`),
            '$1'
          )}
        />
      </Fragment>
    );
  }
}
Stage.propTypes = {
  me: PropTypes.object,
  projectId: PropTypes.string,
  formId: PropTypes.string,
  revisionId: PropTypes.string,
  version: PropTypes.string,
  dataset: PropTypes.any,
  withdrawnBy: PropTypes.any,
  lockedBy: PropTypes.any,
  stage: PropTypes.any,
  finalized: PropTypes.bool
};
