import React from 'react';
import Formilize from '../../rnbc/components/formilize';
import styles from '../../rnbc/styles';
import Form from '../../rnbc/components/form';
import LocationField from './locationField';
import WithdrawalField from './withdrawalField';
import TextField from './textField';
import ApprovalField from './approvalField';
import DatetimeField from './datetimeField';
import SignatureField from './signatureField';
import UserField from './userField';
import FileField from './fileField';
import SubmitField from './submitField';
import TitleField from './titleField';
import PreviewField from './previewField';
import EnumField from './enumField';
import ChopField from './chopField';
import NotifyField from './notifyField';
import FormSyncTaskWorker from './formSyncTaskWorker';
import { AsyncStorage } from '../../plugins';

export default ({
  projectId,
  formId,
  revisionId,
  version,
  id: stageId,
  enabled,
  dataset,
  onSubmit = _ => _,
  onWithdraw = _ => _,
  onNotify = _ => _,
  onWithdrawalApprove,
  onWithdrawalReject,
  onUpdate,
  finalized,
  me = {},
  lockedBy = {},
  withdrawnBy,
  fields = []
}) => {
  const isLocked = !!lockedBy && lockedBy.group && lockedBy.group === me.group;
  const isLockedByMe = isLocked && me.id === lockedBy.id;
  const stageDisabled = (isLocked && !isLockedByMe) || !enabled;

  const initialValues = fields.reduce((reducer, field) => {
    reducer[field.id] = field;
    return reducer;
  }, {});

  return (
    <Formilize initialValues={initialValues} onFieldUpdate={onUpdate}>
      {({ values, setFieldValue }) => (
        <Form style={[styles.padding(10)]}>
          {fields.map(({ id }, i) => {
            const field = values[id],
              { __typename, userGroup, userPositions = [] } = field;
            field.key = `${stageId}:${i}`;
            field.me = me;

            const userGroupDisabled = !!userGroup && userGroup !== me.group;
            const positionDisabled =
              !!userPositions &&
              userPositions.length > 0 &&
              !~userPositions.indexOf(me.position);

            const disabled =
              field.disabled ||
              userGroupDisabled ||
              stageDisabled ||
              positionDisabled;

            const cacheWaitingForSync = {
              _sync: true,
              ...(FormSyncTaskWorker.getCache({
                revisionId,
                stageId: stageId.replace(
                  new RegExp(`^(.*)-${revisionId}$`),
                  '$1'
                ),
                fieldId: id.replace(new RegExp(`^(.*)-${revisionId}$`), '$1')
              }) || { _sync: false })
            };

            /* Prevent empty string in dynamoDB */
            if (field.label === '-') field.label = '';

            switch (__typename) {
              case 'PreviewField':
                return <PreviewField {...field} disabled={disabled} />;
              case 'TitleField':
                return <TitleField {...field} disabled={disabled} />;
              case 'LocationField':
                return (
                  <LocationField
                    {...field}
                    {...cacheWaitingForSync}
                    onChange={location =>
                      setFieldValue(id, { ...field, location })
                    }
                    disabled={disabled}
                  />
                );
              case 'DateTimeField':
                return (
                  <DatetimeField
                    {...field}
                    {...cacheWaitingForSync}
                    onChange={dateTime =>
                      setFieldValue(id, { ...field, dateTime })
                    }
                    disabled={disabled}
                  />
                );
              case 'TextField':
                const values = field.values;
                const useLocalDataset = !(values && values.length > 0);
                return (
                  <TextField
                    {...field}
                    {...cacheWaitingForSync}
                    values={useLocalDataset ? dataset[field.label] : values}
                    onChange={text => {
                      /* If from api, dont store */
                      if (useLocalDataset) {
                        /* Use Pointer behaviour only */
                        dataset[field.label] = [
                          ...new Set([text, ...(dataset[field.label] || [])])
                        ].slice(0, 10);
                        /* store max 10 records */

                        /* Save Dataset */
                        AsyncStorage.setItem(
                          'dataset',
                          `${JSON.stringify(dataset)}`
                        );
                      }

                      setFieldValue(id, { ...field, text });
                    }}
                    disabled={disabled}
                  />
                );
              case 'EnumField':
                return (
                  <EnumField
                    {...field}
                    {...cacheWaitingForSync}
                    onChange={value => setFieldValue(id, { ...field, value })}
                    disabled={disabled}
                  />
                );
              case 'ApprovalField':
                return (
                  <ApprovalField
                    {...field}
                    {...cacheWaitingForSync}
                    onChange={status => setFieldValue(id, { ...field, status })}
                    disabled={disabled}
                  />
                );
              case 'ChopField':
                return (
                  <ChopField
                    {...field}
                    {...cacheWaitingForSync}
                    onChange={chopped =>
                      setFieldValue(id, { ...field, chopped })
                    }
                    disabled={disabled}
                  />
                );
              case 'SignatureField':
                return (
                  <SignatureField
                    {...field}
                    {...cacheWaitingForSync}
                    onChange={signature =>
                      setFieldValue(id, { ...field, signature })
                    }
                    disabled={disabled}
                  />
                );
              case 'UserField':
                return (
                  <UserField
                    {...field}
                    {...cacheWaitingForSync}
                    projectId={projectId}
                    formId={formId}
                    revisionId={revisionId}
                    version={version}
                    stageId={stageId}
                    onChange={users => setFieldValue(id, { ...field, users })}
                    onAcknowledge={noted =>
                      setFieldValue(id, { ...field, noted })
                    }
                    disabled={disabled}
                  />
                );
              case 'NotifyField':
                return (
                  <NotifyField
                    {...field}
                    {...cacheWaitingForSync}
                    projectId={projectId}
                    formId={formId}
                    revisionId={revisionId}
                    version={version}
                    stageId={stageId}
                    onUserChange={users =>
                      setFieldValue(id, { ...field, users })
                    }
                    onMessageChange={message =>
                      setFieldValue(id, { ...field, message })
                    }
                    onPress={() => onNotify(field)}
                    disabled={disabled}
                  />
                );
              case 'FileField':
                return (
                  <FileField
                    {...field}
                    projectId={projectId}
                    formId={formId}
                    revisionId={revisionId}
                    stageId={stageId}
                    onChange={(files, broadcast) =>
                      setFieldValue(id, { ...field, files }, broadcast)
                    }
                    disabled={disabled}
                  />
                );
              case 'WithdrawButton':
                return (
                  <WithdrawalField
                    {...field}
                    finalized={finalized}
                    withdrawnBy={withdrawnBy}
                    onPress={() => onWithdraw(field)}
                    onApprove={() => onWithdrawalApprove(field)}
                    onReject={() => onWithdrawalReject(field)}
                    disabled={disabled}
                  />
                );
              case 'SubmitButton':
                return (
                  <SubmitField
                    {...field}
                    onPress={() => onSubmit(field)}
                    disabled={disabled}
                  />
                );
              default:
                return null;
            }
          })}
        </Form>
      )}
    </Formilize>
  );
};
