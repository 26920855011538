import React, { Component } from 'react';
import {
  StatusBar,
  View,
  ReactApollo,
  SplashScreen,
  Sentry,
  Config,
  DeviceInfo
} from '../plugins';
import { Rehydrated } from 'aws-appsync-react';
import Router from './router';
import styles from '../rnbc/styles';
import Alert from '../rnbc/components/alert';
import ActivityIndicator from '../rnbc/components/activityIndicator';
import ActivityIndicator2 from '../rnbc/components/activityIndicator2';
import NetworkStatus from '../rnbc/components/networkStatus';
import { client } from './shared/apollo';
import FileTaskWorker from './component/fileTaskWorker';
import FormSyncTaskWorker from './component/formSyncTaskWorker';
import OneSignalWorker from '../rnbc/components/oneSignalWorker';
import ActionSheet from '../rnbc/components/actionSheet';

console.disableYellowBox = true;

const loadingComponent = (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      backgroundColor: 'black',
      color: 'white'
    }}
  >
    Initializing, please wait ...
  </div>
);

export default class extends Component {
  componentDidMount() {
    try {
      SplashScreen.hide();
      if (!Config.DEBUG)
        Sentry.init({
          release: DeviceInfo.getVersion(),
          dsn: Config.REACT_APP_SENTRY_DSN,
          beforeSend: (event) => {
            try {
              if (window.location.hostname === 'localhost') return null;
            } catch (e) {}
            return event;
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <ReactApollo.ApolloProvider client={client}>
        <Rehydrated loading={loadingComponent}>
          <View style={[styles.backgroundColor('white')]}>
            <StatusBar barStyle={'light-content'} />
            <Router />
            <ActivityIndicator />
            <ActivityIndicator2 />
            <Alert />
            <FileTaskWorker />
            <FormSyncTaskWorker />
            <OneSignalWorker />
            <ActionSheet />
            <NetworkStatus />
          </View>
        </Rehydrated>
      </ReactApollo.ApolloProvider>
    );
  }
}
