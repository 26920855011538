import React from 'react';
import {
  FileInputMultipleS3_2,
  FileInputS3_2,
  FormGroup,
  Label,
  Small
} from '../../rnbc/components/form';
import Text from '../../rnbc/components/text';
import { OS } from '../../plugins';
import FileTaskWorker from '../component/fileTaskWorker';
import { preferDateFormat } from '../shared/isTC';
import { Row } from '../../rnbc/components/layout';
import FieldUserGroupPositionsRemark from './FieldUserGroupPositionsRemark';
import styles from '../../rnbc/styles';

export default ({
  id: fieldId,
  label,
  disabled,
  projectId,
  formId,
  revisionId,
  stageId,
  files: _files,
  onChange = _ => _,
  numberOfFiles = 1,
  required,
  description,
  allow = [],
  userGroup,
  userPositions
}) => {
  const files = _files || [];
  const imageOnly = (allow || [])[0] === 'image/*';
  return (
    <FormGroup>
      {!!label && (
        <Row fy={'center'}>
          <Label style={[styles.marginY(5, !!description ? 0 : 5)]}>
            {label} <Text color={'danger'}>{!!required && '**'}</Text>
          </Label>
          <FieldUserGroupPositionsRemark
            userPositions={userPositions}
            userGroup={userGroup}
          />
        </Row>
      )}
      {!!description && (
        <Small style={[styles.marginBottom(5)]}>{description}</Small>
      )}
      {numberOfFiles > 1 ? (
        <FileInputMultipleS3_2
          timeFormat={`${preferDateFormat} HH:mm`}
          style={[{ maxWidth: 500 }]}
          count={numberOfFiles}
          disabled={disabled}
          value={files}
          imageOnly={imageOnly}
          maxSize={1048576 * 10}
          onFileSelect={async (files, i) => {
            onChange(files, false);

            const fixedFieldId = fieldId.replace(
              new RegExp(`^(.*)-${revisionId}$`),
              '$1'
            );

            const insertedIds = [].concat(i);
            for (let i of insertedIds) {
              if (OS === 'web') {
                FileTaskWorker.addTask({
                  projectId,
                  formId,
                  revisionId,
                  stageId,
                  fieldId: fixedFieldId,
                  file: files[i],
                  i
                });
              } else {
                const { uri, name, type } = files[i];
                FileTaskWorker.addTask({
                  projectId,
                  formId,
                  revisionId,
                  stageId,
                  fieldId: fixedFieldId,
                  file: { uri, name, type },
                  i
                });
              }
            }
          }}
          onChange={files => {
            onChange(files);
          }}
        />
      ) : (
        <FileInputS3_2
          style={[{ maxWidth: 500 }]}
          disabled={disabled}
          value={files[0]}
          imageOnly={imageOnly}
          maxSize={1048576 * 10}
          onFileSelect={async file => {
            onChange([file], false);

            const fixedFieldId = fieldId.replace(
              new RegExp(`^(.*)-${revisionId}$`),
              '$1'
            );

            if (OS === 'web') {
              FileTaskWorker.addTask({
                projectId,
                formId,
                revisionId,
                stageId,
                fieldId: fixedFieldId,
                file,
                i: 0
              }).then();
            } else {
              const { uri, name, type } = file;
              FileTaskWorker.addTask({
                projectId,
                formId,
                revisionId,
                stageId,
                fieldId,
                file: { uri, name, type },
                i: 0
              }).then();
            }
          }}
          onChange={s3 => {
            onChange([s3]);
          }}
          timeFormat={`${preferDateFormat} HH:mm`}
        />
      )}
    </FormGroup>
  );
};
