import React, { Component } from 'react';
import { Route, Switch, Sentry } from '../../../plugins';
import Login from '../Login';
import Projects from '../Projects';
import Forms from '../Forms';
import Revisions from '../Revisions';
import Revision from '../Revision';
import Setting, { ClearCacheForLogout } from '../Setting';
import ResetPassword from '../ResetPassword';
import Register from '../Register';
import Report from '../Report';
import FormReport from '../FormReport';
import Search from '../Search';
import Signature from '../Signature';
import SearchResults from '../Search/results';
import Test from '../Test';
import FullWrapper from '../../../rnbc/components/fullWrapper';
import ErrorBoundary from '../../../rnbc/components/errorBoundary';

let inst;
export default class extends Component {
  componentDidMount() {
    inst = this;
  }
  componentWillUnmount() {
    inst = null;
  }
  onError = async (error, resolve) => {
    const { history } = this.props;
    Sentry.captureMessage(`Error Boundary: ${error.message}`);
    await ClearCacheForLogout();
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (!!inst) {
      resolve();
      try {
        window.crash_test = true;
      } catch (e) {}
      history.replace('/');
    }
  };
  static getHistory = () => {
    return !!inst && inst.props.history;
  };
  render() {
    return (
      <FullWrapper>
        <ErrorBoundary onError={this.onError}>
          <Switch>
            <Route exact={true} path={'/Login'} component={Login} />
            <Route exact={true} path={'/Projects'} component={Projects} />
            <Route exact={true} path={'/Forms/:projectId'} component={Forms} />
            <Route
              exact={true}
              path={'/Revisions/:projectId/:formId/:viewId/:cursors?'}
              component={Revisions}
            />
            <Route
              path={'/Revision/:projectId/:formId/:revisionId/:stageId?'}
              component={Revision}
            />
            <Route exact={true} path={'/Setting'} component={Setting} />
            <Route
              exact={true}
              path={'/ResetPassword'}
              component={ResetPassword}
            />
            <Route exact={true} path={'/Register'} component={Register} />
            <Route
              exact={true}
              path={'/Search/:projectId/:formId'}
              component={Search}
            />
            <Route
              exact={true}
              path={'/Report/:projectId/:formId'}
              component={FormReport}
            />
            <Route
              exact={true}
              path={'/Report/:projectId'}
              component={Report}
            />
            <Route
              exact={true}
              path={'/SearchResults/:projectId/:formId/:input/:cursors?'}
              component={SearchResults}
            />
            <Route exact={true} path={'/Signature'} component={Signature} />
            <Route exact={true} path={'/Test'} component={Test} />
            <Route component={Login} />
          </Switch>
        </ErrorBoundary>
      </FullWrapper>
    );
  }
}
