import React from 'react';
import styles from '../../../rnbc/styles';
import Card, { CardBody } from '../../../rnbc/components/card';
import H1 from '../../../rnbc/components/h1';
import { Text } from '../../../plugins';

export default ({ label, value, style }) => {
  return (
    <Card style={[styles.flex(1)].concat(style)}>
      <CardBody>
        <Text>{label}</Text>
        <H1>{value}</H1>
      </CardBody>
    </Card>
  );
};
