import React from 'react';
import { Text } from '../../plugins';
import styles from '../styles';

export default ({ children, color = 'dark', style, ...props }) => (
  <Text
    {...props}
    style={[styles.bold, styles.color(`${color}`), styles.h2].concat(style)}
  >
    {children}
  </Text>
);
