import React from 'react';
import { Row } from '../../../rnbc/components/layout';
import Text from '../../../rnbc/components/text';
import Icon from '../../../rnbc/icon';
import styles from '../../../rnbc/styles';
import moment from 'moment';

export default field => {
  const { id, columnWidth, label, shortLabel, __typename } = field || {};
  if (!label && !shortLabel) return null;
  const displayLabel = shortLabel || label,
    commonSetting = { label: displayLabel, field: id, width: columnWidth };

  switch (__typename) {
    case 'ApprovalField':
      return {
        ...commonSetting,
        render: row => {
          const { finalized, withdrawnBy, _color, _textStyle } = row || {},
            isWithdrawn = !!finalized && !!withdrawnBy,
            value = isWithdrawn ? 'Withdraw' : row[id];
          return (
            <Row fy={'center'} style={[styles.fill]}>
              <Text
                numberOfLines={1}
                style={[
                  styles.flex(1),
                  styles.ellipsis,
                  { minWidth: 100 }
                ].concat(_textStyle)}
                color={_color}
              >
                {value}
              </Text>
            </Row>
          );
        }
      };
    case 'DateTimeField':
      return {
        ...commonSetting,
        render: (row, { late, display }) => {
          return (
            <Row fy={'center'} style={[styles.fill, { position: 'relative' }]}>
              {!!late && (
                <Text
                  color={'white'}
                  style={[
                    styles.absolute({ top: -10, left: -10 }),
                    styles.padding(0, 10),
                    styles.backgroundColor('danger'),
                    styles.rounded(20)
                  ]}
                >
                  late
                </Text>
              )}
              <Text
                color={row._color}
                style={[styles.flex(1), styles.ellipsis].concat(row._textStyle)}
              >
                {display}
              </Text>
            </Row>
          );
        }
      };
    case 'SignatureField':
      return {
        ...commonSetting,
        render: (row, name) => {
          if (!name) return null;
          return (
            <Row fy={'center'} style={[styles.fill]}>
              <Icon
                name={'check'}
                color={row._color || 'dark'}
                style={[styles.marginRight(10)]}
              />
              <Text
                color={row._color}
                style={[styles.flex(1), styles.ellipsis].concat(row._textStyle)}
              >
                {name}
              </Text>
            </Row>
          );
        }
      };
    case 'UserField':
      return {
        ...commonSetting,
        render: (row, { label, noted }) => {
          if (!label) return null;
          return (
            <Row fy={'center'} style={[styles.fill, { position: 'relative' }]}>
              {noted && (
                <Text
                  color={'white'}
                  style={[
                    styles.absolute({ top: -10, left: -10 }),
                    styles.padding(0, 10),
                    styles.backgroundColor('danger'),
                    styles.rounded(20)
                  ]}
                >
                  noted
                </Text>
              )}
              <Text
                color={row._color}
                style={[styles.flex(1), styles.ellipsis].concat(row._textStyle)}
              >
                {label}
              </Text>
            </Row>
          );
        }
      };
    case 'WithdrawButton':
      return {
        ...commonSetting,
        render: (row, name) => {
          if (!name) return null;
          if (!!row.finalized)
            return (
              <Row fy={'center'} style={[styles.fill]}>
                <Icon
                  name={'check'}
                  color={row._color || 'dark'}
                  style={[styles.marginRight(10)]}
                />
                <Text
                  color={row._color}
                  style={[styles.flex(1), styles.ellipsis].concat(
                    row._textStyle
                  )}
                >
                  {name}
                </Text>
              </Row>
            );
          return (
            <Row fy={'center'} style={[styles.fill]}>
              <Icon
                name={'question'}
                color={'danger'}
                style={[styles.marginRight(10)]}
              />
              <Text
                color={row._color}
                style={[styles.flex(1), styles.ellipsis].concat(row._textStyle)}
              >
                {name}
              </Text>
            </Row>
          );
        }
      };
    default:
      return commonSetting;
  }
};
