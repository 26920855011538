import { ApolloBoost } from '../../../plugins';
const { gql } = ApolloBoost;

export const GET_BADGES = (projectId, formId, viewIds = []) => gql`
  query {
    me {
      id
      p_${projectId.replace(/-/g, '_')}: project(id: "${projectId}") {
        id
        f_${formId.replace(/-/g, '_')}:form(id: "${formId}") {
          id
          ${viewIds
            .map(
              id =>
                `revs_${id.replace(
                  /\-/g,
                  '_'
                )}: revisionsCount(templateViewId: "${id}")`
            )
            .join('\n')}
        }
      }
    }
  }
`;
export const GET_VIEWS = (projectId, formId) => gql`
  query {
    me {
      id
      p_${projectId.replace(/-/g, '_')}: project(id: "${projectId}") {
        id
        f_${formId.replace(/-/g, '_')}: form(id: "${formId}") {
          id
          template {
            id
            views {
              id
              label
            }
          }
        }
      }
    }
  }
`;

export const GET_TABLE_COLUMNS = (projectId, formId) => gql`
  query {
    me {
      id
      p_${projectId.replace(/-/g, '_')}: project(id: "${projectId}") {
        id
        f_${formId.replace(/-/g, '_')}: form(id: "${formId}") {
          id
          template {
            id
            stages {
              fields {
                id
                showInList
                columnWidth
                shortLabel
                label
                __typename
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_REVISION_ROWS_REVAMPED_NANO = (projectId, formId) => gql`
    query($cursor: ID, $limits: Int, $sortBy: [SortingInput!], $view: String, $filter: [ProjectSearchInput!]) {
        me {
            id
            p_${projectId.replace(/-/g, '_')}: project(id: "${projectId}") {
                id
                f_${formId.replace(/-/g, '_')}: form(id: "${formId}") {
                    id
                    revisionsWithData(cursor: $cursor, view: $view, limits: $limits, sortBy: $sortBy, filter: $filter) {
                        nextCursor
                        totalCount
                        nodes {
                            id
                        }
                    }
                }
            }
        }
    }
`;
export const GET_REVISION_ROWS_REVAMPED = (projectId, formId) => gql`
    query($cursor: ID, $limits: Int, $sortBy: [SortingInput!], $view: String, $filter: [ProjectSearchInput!]) {
        me {
            id
            p_${projectId.replace(/-/g, '_')}: project(id: "${projectId}") {
                id
                f_${formId.replace(/-/g, '_')}: form(id: "${formId}") {
                    id
                    revisionsWithData(cursor: $cursor, view: $view, limits: $limits, sortBy: $sortBy, filter: $filter) {
                        nextCursor
                        totalCount
                        nodes {
                            id
                            revision
                            finalized
                            firstSubmittedAt
                            withdrawnBy {
                              id
                              name
                            }
                            resubmittingRevisions
                            fields {
                                id
                                __typename
                                label
                                showInList
                                shortLabel
                                columnWidth
                                updatedAt
                                updatedBy {
                                  id
                                  name
                                }
                                ... on TextField {
                                  text
                                }
                                ... on LocationField {
                                  location {
                                    name
                                    level
                                    grid
                                    area
                                  }
                                }
                                ... on DateTimeField {
                                  dateTime
                                }
                                ... on PreviewField {
                                  downloadUrl
                                }
                                ... on UserField {
                                  noted
                                  users {
                                    id
                                    name
                                  }
                                }
                                ... on FileField {
                                  files {
                                    key
                                  }
                                }
                                ... on ApprovalField {
                                  status
                                  numberOfButton
                                }
                                ... on ChopField {
                                  chopped
                                }
                                ... on EnumField {
                                  value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const FORM_REVISION_CREATE = gql`
  mutation(
    $projectFormId: ID!
    $sourceRevision: ID
    $copyDescription: Boolean
    $copyRevision: ID
  ) {
    formRevisionCreate(
      projectFormId: $projectFormId
      sourceRevision: $sourceRevision
      copyDescription: $copyDescription
      copyRevision: $copyRevision
    ) {
      id
    }
  }
`;

export const FORM_REVISION_GENCSV = gql`
  mutation($formId: ID!, $templateViewId: String!) {
    formRevisionGenCSV(formId: $formId, templateViewId: $templateViewId)
  }
`;
