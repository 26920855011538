import React, { Fragment } from 'react';
import {
  FormGroup,
  InputGroup,
  InputGroupAppend,
  Label,
  ModalizedInputDate,
  ModalizedInputTime
} from '../../../rnbc/components/form';
import { Row } from '../../../rnbc/components/layout';
import Text from '../../../rnbc/components/text';
import styles from '../../../rnbc/styles';
import Icon from '../../../rnbc/icon';
import Button from '../../../rnbc/components/button';
import { selectableMinuteInterval } from '../../shared/isTC';

export default ({
  label,
  important,
  since: [sinceDate, sinceTime] = [],
  until: [untilDate, untilTime] = [],
  onChange = _ => _
}) => {
  return (
    <Fragment>
      <FormGroup>
        <Label style={[!!important && styles.color('danger')]}>{label}</Label>
        <Row>
          <InputGroup style={[styles.flex(1), { maxWidth: 500 }]}>
            <InputGroupAppend>
              <Text>Since</Text>
            </InputGroupAppend>
            <ModalizedInputDate
              minDay={null}
              value={sinceDate}
              style={[styles.flex(1)]}
              onChange={sinceDate =>
                onChange([sinceDate, sinceTime], [untilDate, untilTime])
              }
            />
            <InputGroupAppend>
              <Icon name={'calendar-alt'} />
            </InputGroupAppend>
            <ModalizedInputTime
              minuteInterval={selectableMinuteInterval}
              value={sinceTime}
              style={[styles.flex(1)]}
              onChange={sinceTime =>
                onChange([sinceDate, sinceTime], [untilDate, untilTime])
              }
            />
            <InputGroupAppend>
              <Icon name={'clock'} />
            </InputGroupAppend>
          </InputGroup>
          {(!!sinceDate || !!sinceTime) && (
            <Button
              onPress={() => {
                onChange(undefined, [untilDate, untilTime]);
              }}
              transparent
              color={'danger'}
            >
              <Icon name={'times'} />
            </Button>
          )}
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <InputGroup style={[styles.flex(1), { maxWidth: 500 }]}>
            <InputGroupAppend>
              <Text> Until </Text>
            </InputGroupAppend>
            <ModalizedInputDate
              minDay={null}
              value={untilDate}
              style={[styles.flex(1)]}
              onChange={untilDate =>
                onChange([sinceDate, sinceTime], [untilDate, untilTime])
              }
            />
            <InputGroupAppend>
              <Icon name={'calendar-alt'} />
            </InputGroupAppend>
            <ModalizedInputTime
              minuteInterval={selectableMinuteInterval}
              value={untilTime}
              style={[styles.flex(1)]}
              onChange={untilTime =>
                onChange([sinceDate, sinceTime], [untilDate, untilTime])
              }
            />
            <InputGroupAppend>
              <Icon name={'clock'} />
            </InputGroupAppend>
          </InputGroup>
          {(!!untilDate || !!untilTime) && (
            <Button
              onPress={() => {
                onChange([sinceDate, sinceTime], undefined);
              }}
              transparent
              color={'danger'}
            >
              <Icon name={'times'} />
            </Button>
          )}
        </Row>
      </FormGroup>
    </Fragment>
  );
};
