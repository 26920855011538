import React, { Fragment } from 'react';
import {
  ScrollView,
  ReactApollo,
  AsyncStorage,
  DeviceInfo,
  StorageEngine
} from '../../../plugins';
import { Col } from '../../../rnbc/components/layout';
import List, { ListItem } from '../../../rnbc/components/list';
import ListBreak from '../../../rnbc/components/listBreak';
import Loading from '../../../rnbc/components/loading';
import NavHeader from '../../../rnbc/components/navHeader';
import { BottomPadder } from '../../../rnbc/components/iphonePadder';
import FileTaskWorker from '../../component/fileTaskWorker';
import FormSyncTaskWorker from '../../component/formSyncTaskWorker';
import styles from '../../../rnbc/styles';
import { client } from '../../shared/apollo';
import { alert } from '../../shared/popup';
import { GET_ME } from './_gql';
import moment from 'moment';
import { preferDateFormat } from '../../shared/isTC';

export const ClearCacheForLogout = async () => {
  try {
    await AsyncStorage.removeItem('token');
    await AsyncStorage.removeItem('reduxPersist:appsync');
    await AsyncStorage.removeItem('reduxPersist:appsync-metadata');
    await AsyncStorage.removeItem('reduxPersist:offline');
  } catch (e) {}
  try {
    await client.resetStore();
  } catch (e) {}
  try {
    const hydrated = await client.hydrated();
    await hydrated.resetStore();
  } catch (e) {}
  try {
    FileTaskWorker.clearTask();
    await FileTaskWorker.saveTask();
  } catch (e) {}
  try {
    FormSyncTaskWorker.clearTask();
    await FormSyncTaskWorker.saveTask();
  } catch (e) {}
  try {
    await StorageEngine.removeItem('reduxPersist:appsync');
    await StorageEngine.removeItem('reduxPersist:appsync-metadata');
    await StorageEngine.removeItem('reduxPersist:offline');
  } catch (e) {}
};

export default ({ history }) => {
  return (
    <Col style={[styles.blockh]}>
      <NavHeader title={'Setting'} back history={history} />
      <ScrollView>
        <List>
          <ReactApollo.Query query={GET_ME}>
            {({ loading, data }) => {
              if (loading) return <Loading />;
              const { me } = data || {},
                { name, company, group, email, createdAt, position } = me || {};
              return (
                <Fragment>
                  <ListItem title={'Name'} value={name} />
                  <ListItem title={'Company'} value={company} />
                  <ListItem title={'Group'} value={group} />
                  <ListItem title={'Position'} value={position} />
                  <ListItem title={'Email'} value={email} />
                  <ListItem
                    border={false}
                    title={'From'}
                    value={moment(createdAt).format(preferDateFormat)}
                  />
                </Fragment>
              );
            }}
          </ReactApollo.Query>
          <ListBreak />
          <ListItem title={'Version'} value={DeviceInfo.getVersion()} />
          <ListItem
            title={'My Signature'}
            onPress={() => {
              history.push('/Signature');
            }}
          />
          <ListItem
            title={'Reset Password'}
            onPress={() => {
              history.push('/ResetPassword');
            }}
          />
          <ListItem
            title={'Logout'}
            titleColor={'danger'}
            icon={false}
            border={false}
            onPress={async () => {
              try {
                await alert('', 'Sure to Logout?');
                await ClearCacheForLogout();
                history.replace('Login');
              } catch (e) {}
            }}
          />
        </List>
        <BottomPadder />
      </ScrollView>
    </Col>
  );
};
