import React, { Component, Fragment } from 'react';
import { ReactApollo } from '../../../plugins';
import { Col, Flex } from '../../../rnbc/components/layout';
import styles from '../../../rnbc/styles';
import Icon from '../../../rnbc/icon';
import NavHeader from '../../../rnbc/components/navHeader';
import Button from '../../../rnbc/components/button';
import Filter, { FilterSkeleton } from './_filter';
import { client } from '../../shared/apollo';
import { FORM_REVISION_CREATE, GET_VIEWS, FORM_REVISION_GENCSV } from './_gql';
import Alert from '../../../rnbc/components/alert';
import { errorParser } from '../../shared/errorParser';
import { alert } from '../../shared/popup';
import RevisionRowsTable from './_revisionRowsTable';
import ActivityIndicator from '../../../rnbc/components/activityIndicator';

export default class extends Component {
  constructor(...args) {
    super(...args);
    const { match: { params } = {} } = this.props,
      { viewId, cursors = '' } = params || {};
    this.state = {
      filterId: viewId,
      key_main: 0,
      cursors: cursors.split(',').filter(_ => _),
      nextCursor: undefined,
      totalCount: 0,
      currentCount: 0
    };
  }
  /* for name display in Search scene */
  currentFilter = undefined;

  onConnection = ({ nextCursor, totalCount, nodes }) => {
    const currentCount = (nodes || []).length;
    if (currentCount !== this.state.currentCount)
      this.setState({ currentCount });
    if (totalCount !== this.state.totalCount) this.setState({ totalCount });
    if (
      nextCursor !== this.state.nextCursor &&
      !~this.state.cursors.indexOf(nextCursor)
    )
      this.setState({ nextCursor });
  };
  onFilterChange = ({ id }) => {
    const { history, match: { params } = {} } = this.props;

    NavHeader.resetLastBackReferrer();
    const { projectId, formId } = params || {};
    history.replace(`/Revisions/${projectId}/${formId}/${id}`);
    this.setState({
      nextCursor: undefined,
      cursors: []
    });
  };
  onNextPress = async () => {
    const {
        history,
        match: { params } = {},
        location: { state } = {}
      } = this.props,
      { projectId, formId, viewId } = params || {};
    const { nextCursor, cursors } = this.state;

    if (!!nextCursor) {
      NavHeader.resetLastBackReferrer();
      const _cursors = cursors.concat(nextCursor);
      history.replace({
        pathname: `/Revisions/${projectId}/${formId}/${viewId}/${_cursors.join(
          ','
        )}`,
        state
      });
      this.setState({
        nextCursor: undefined,
        cursors: _cursors
      });
    }
  };
  onPrevPress = async () => {
    const {
        history,
        match: { params } = {},
        location: { state } = {}
      } = this.props,
      { projectId, formId, viewId } = params || {};
    const { cursors } = this.state;
    if (cursors.length > 0) {
      NavHeader.resetLastBackReferrer();
      const nextCursor = cursors.pop();
      history.replace({
        pathname: `/Revisions/${projectId}/${formId}/${viewId}/${cursors.join(
          ','
        )}`,
        state
      });
      this.setState({
        nextCursor,
        cursors: [...cursors]
      });
    }
  };
  onFirstPress = async () => {
    const {
        history,
        match: { params } = {},
        location: { state } = {}
      } = this.props,
      { projectId, formId, viewId } = params || {};
    const { cursors } = this.state;
    if (cursors.length > 0) {
      NavHeader.resetLastBackReferrer();
      const nextCursor = cursors[0];
      history.replace({
        pathname: `/Revisions/${projectId}/${formId}/${viewId}`,
        state
      });
      this.setState({
        nextCursor,
        cursors: []
      });
    }
  };
  onRefreshPress = async () => {
    NavHeader.resetLastBackReferrer();
    this.setState({ key_main: Math.random() });
  };
  onResubmitPress = async revision => {
    const { match: { params } = {} } = this.props,
      { formId } = params || {};
    try {
      await alert('', 'Create Resubmit Form?');
      ActivityIndicator.show();
      const {
        data: { formRevisionCreate }
      } = await client.mutate({
        mutation: FORM_REVISION_CREATE,
        variables: {
          projectFormId: formId,
          sourceRevision: revision.id,
          copyRevision: revision.id
        }
      });
      Alert.success('Resubmit Added');
      console.log(formRevisionCreate);
      this.onRowEditPress(formRevisionCreate);
    } catch (e) {
      console.log(e);
      Alert.danger(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  };
  onResubmittingRevisionPress = id => {
    const { history, match: { params } = {} } = this.props,
      { formId, projectId } = params || {};

    history.push({
      pathname: `/Revision/${projectId}/${formId}/${id}`,
      state: {
        title: ''
      }
    });
  };
  onRowEditPress = revision => {
    const { history, match: { params } = {} } = this.props,
      { formId, projectId } = params || {};

    if (!revision || !revision.id) {
      Alert.danger('Invalid Revision');
    } else {
      history.push({
        pathname: `/Revision/${projectId}/${formId}/${revision.id}`,
        state: {
          title: ''
        }
      });
    }
  };
  onExportData = async () => {
    const { match: { params } = {} } = this.props,
      { formId, viewId: filterId } = params || {};

    try {
      ActivityIndicator.show();

      await client.mutate({
        mutation: FORM_REVISION_GENCSV,
        variables: {
          formId,
          templateViewId: filterId
        }
      });

      Alert.info('Exported file will be sent to your email.');
    } catch (e) {
      Alert.danger(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  };
  onAddPress = async () => {
    const { history, match: { params } = {} } = this.props,
      { projectId, formId } = params || {};
    try {
      await alert('', 'Create New Form?');
      ActivityIndicator.show();
      const {
        data: { formRevisionCreate }
      } = await client.mutate({
        mutation: FORM_REVISION_CREATE,
        variables: {
          projectFormId: formId
        }
      });
      const { id } = formRevisionCreate || {};
      history.push({
        pathname: `/Revision/${projectId}/${formId}/${id}`,
        state: {
          title: ''
        }
      });
    } catch (e) {
      console.log(e);
      Alert.danger(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  };
  onSearchPress = () => {
    const { history, match: { params } = {} } = this.props,
      { projectId, formId } = params || {};
    history.push({
      pathname: `/Search/${projectId}/${formId}`,
      state: {
        view: this.currentFilter
      }
    });
  };
  render() {
    const {
        history,
        match: { params } = {},
        location: { state: { title } = {} } = {}
      } = this.props,
      { projectId, formId, viewId: filterId } = params || {},
      { key_main, cursors, nextCursor, totalCount, currentCount } = this.state;

    return (
      <Col style={[styles.blockh]} key={key_main}>
        <NavHeader
          title={title}
          back
          history={history}
          rightComponent={
            <Fragment>
              <Button transparent color={'light'} onPress={this.onSearchPress}>
                <Icon name={'search'} />
              </Button>
              <Button transparent color={'light'} onPress={this.onAddPress}>
                <Icon name={'plus'} />
              </Button>
            </Fragment>
          }
        />
        <ReactApollo.Query
          query={GET_VIEWS(projectId, formId)}
          fetchPolicy={
            NavHeader.lastBackReferrer.match('/Revision/')
              ? 'cache-first'
              : 'cache-and-network'
          }
          onCompleted={data => {
            if (!this.currentFilter) {
              const { me } = data || {},
                form =
                  ((me || {})[`p_${projectId.replace(/-/g, '_')}`] || {})[
                    `f_${formId.replace(/-/g, '_')}`
                  ] || {},
                { template: { views } = {} } = form || {};
              this.currentFilter = views.find(view => view.id === filterId);
            }
          }}
        >
          {({ loading, data }) => {
            if (loading && !data) return <FilterSkeleton />;
            const { me } = data || {},
              form =
                ((me || {})[`p_${projectId.replace(/-/g, '_')}`] || {})[
                  `f_${formId.replace(/-/g, '_')}`
                ] || {},
              { template: { views } = {} } = form || {};

            return (
              <Filter
                cursors={cursors}
                nextCursor={nextCursor}
                totalCount={totalCount}
                currentCount={currentCount}
                loading={loading}
                projectId={projectId}
                formId={formId}
                filterId={filterId}
                filters={views || []}
                onFilterChange={this.onFilterChange}
                onRefreshPress={this.onRefreshPress}
                onNextPress={this.onNextPress}
                onPrevPress={this.onPrevPress}
                onFirstPress={this.onFirstPress}
              />
            );
          }}
        </ReactApollo.Query>
        <Flex>
          <RevisionRowsTable
            cursor={cursors[cursors.length - 1]}
            projectId={projectId}
            formId={formId}
            filterId={filterId}
            onExportData={this.onExportData}
            onResubmitPress={this.onResubmitPress}
            onEditPress={this.onRowEditPress}
            onConnection={this.onConnection}
            onResubmittingRevisionPress={this.onResubmittingRevisionPress}
          />
        </Flex>
      </Col>
    );
  }
}
