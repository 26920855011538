import React from 'react';
import PropTypes from 'prop-types';
import { View } from '../../plugins';
import FullWrapper from './fullWrapper';
import styles from '../styles';

export const Row = ({
  style = [],
  flex = 0,
  children,
  fx,
  fy,
  width,
  wrap = false,
  spacing = 0,
  ...props
}) => {
  if (spacing > 0) {
    const items = [];
    React.Children.forEach(children, (child, i) => {
      if (child)
        items.push(
          React.cloneElement(child, {
            key: i,
            style: [i !== 0 && styles.marginLeft(spacing)].concat(
              child.props.style
            )
          })
        );
    });
    return (
      <View
        {...props}
        style={[
          styles.row,
          flex && styles.flex(flex),
          fx ? { justifyContent: fx } : null,
          fy ? { alignItems: fy } : null,
          !!wrap && { flexWrap: 'wrap' }
        ].concat(style)}
      >
        {items}
      </View>
    );
  }

  return (
    <View
      {...props}
      style={[
        styles.row,
        flex && styles.flex(flex),
        fx ? { justifyContent: fx } : null,
        fy ? { alignItems: fy } : null,
        !!wrap && { flexWrap: 'wrap' }
      ].concat(style)}
    >
      {children}
    </View>
  );
};
Row.propTypes = {
  style: PropTypes.any,
  flex: PropTypes.number,
  fy: PropTypes.string,
  fx: PropTypes.string,
  wrap: PropTypes.bool
};

export const Col = ({
  style = [],
  flex = 0,
  children,
  fx,
  fy,
  spacing = 0,
  ...props
}) => {
  if (spacing > 0) {
    const items = [];
    React.Children.forEach(children, (child, i) => {
      if (child)
        items.push(
          React.cloneElement(child, {
            key: i,
            style: [i !== 0 && styles.marginTop(spacing)].concat(
              child.props.style
            )
          })
        );
    });

    return (
      <View
        {...props}
        style={[
          styles.column,
          flex && styles.flex(flex),
          fy ? { justifyContent: fy } : null,
          fx ? { alignItems: fx } : null
        ].concat(style)}
      >
        {items}
      </View>
    );
  }

  return (
    <View
      {...props}
      style={[
        styles.column,
        flex && styles.flex(flex),
        fy ? { justifyContent: fy } : null,
        fx ? { alignItems: fx } : null
      ].concat(style)}
    >
      {children}
    </View>
  );
};
Col.propTypes = {
  style: PropTypes.any,
  flex: PropTypes.number,
  fx: PropTypes.string,
  fy: PropTypes.string
};

export const Flex = props => {
  let { style, flex = 1, children } = props;
  return (
    <View {...props} style={[{ flex }].concat(style)}>
      {children}
    </View>
  );
};

Flex.propTypes = {
  style: PropTypes.any,
  flex: PropTypes.number
};

export class Responsively extends FullWrapper {
  static propTypes = {
    ...FullWrapper.propTypes,
    layout: PropTypes.any
  };
  static defaultProps = {
    ...FullWrapper.defaultProps,
    layout: { sm: Col, lg: Row }
  };
  selectValidValue = (...args) => {
    return args.find(arg => arg !== undefined && arg !== null);
  };
  selectVWConfig = (config = {}) => {
    const { width } = this.state;
    let select;
    const { xs, sm, md, lg, xl } = styles.responsiveBreakPoint;
    if (xl <= width)
      select = this.selectValidValue(
        config.xl,
        config.lg,
        config.md,
        config.sm,
        config.xs,
        config.default
      );
    if (width < xl)
      select = this.selectValidValue(
        config.lg,
        config.md,
        config.sm,
        config.xs,
        config.default,
        config.xl
      );
    if (width < lg)
      select = this.selectValidValue(
        config.md,
        config.sm,
        config.xs,
        config.default,
        config.lg,
        config.xl
      );
    if (width < md)
      select = this.selectValidValue(
        config.sm,
        config.xs,
        config.default,
        config.md,
        config.lg,
        config.xl
      );
    if (width < sm)
      select = this.selectValidValue(
        config.xs,
        config.default,
        config.sm,
        config.md,
        config.lg,
        config.xl
      );
    if (width <= xs)
      select = this.selectValidValue(
        config.default,
        config.xs,
        config.sm,
        config.md,
        config.lg,
        config.xl
      );
    return select;
  };
  getCurrentMPoint = () => {
    const { width } = this.state;
    const { xs, sm, md, lg, xl } = styles.responsiveBreakPoint;
    if (xl <= width) return 'xl';
    if (xl > width && width >= lg) return 'lg';
    if (lg > width && width >= md) return 'md';
    if (md > width && width >= sm) return 'sm';
    if (sm > width && width >= xs) return 'xs';
    return 'xs';
  };
  selectFilter = input => {
    try {
      if (
        typeof input === 'object' &&
        this.selectValidValue(
          input.xs,
          input.sm,
          input.md,
          input.lg,
          input.xl,
          input.default
        )
      )
        return this.selectVWConfig(input);
    } catch (e) {}
    return input;
  };
  render() {
    const { layout, ...props } = this.props;
    const Container = this.selectFilter(layout) || Col;
    const _props = Object.keys(props).reduce(
      (reducer, key) => ({ ...reducer, [key]: this.selectFilter(props[key]) }),
      {}
    );

    const children = _props.children;
    if (typeof children === 'function')
      return children({
        Container,
        responsiveSize: this.getCurrentMPoint(),
        ..._props
      });
    else return <Container {..._props} />;
  }
}
