import React from 'react';
import Report from '../Report';
import { ReactApollo, View } from '../../../plugins';
import { GET_REPORT } from './_gql';
import moment from 'moment';
import Loading from '../../../rnbc/components/loading';
import { Col, Flex, Responsively, Row } from '../../../rnbc/components/layout';
import styles from '../../../rnbc/styles';
import DataCard from '../Report/DataCard';
import DataCard2 from '../Report/DataCard2';
import ChartPie from '../../../rnbc/components/chart/pie';
import Text from '../../../rnbc/components/text';
import H2 from '../../../rnbc/components/h2';
import H5 from '../../../rnbc/components/h5';
import NavHeader from '../../../rnbc/components/navHeader';
import Icon from '../../../rnbc/icon';
import Button from '../../../rnbc/components/button';

export default class extends Report {
  state = {
    ...this.state,
    formName: ''
  };

  renderNavHeader() {
    const { history } = this.props,
      { formName } = this.state;
    return (
      <NavHeader
        title={'Inspection Dash Board'}
        leftComponent={
          <Button
            transparent
            color={'light'}
            style={[styles.paddingRight(30)]}
            onPress={() => {
              history.goBack();
            }}
          >
            <Icon name={'arrow-left'} />
            <H5 color={'light'}>{formName}</H5>
          </Button>
        }
        history={history}
      />
    );
  }

  renderData() {
    const { match: { params } = {} } = this.props,
      { projectId, formId } = params || {},
      { fromDay, toDay } = this.state;
    return (
      <View>
        <ReactApollo.Query
          query={GET_REPORT}
          onCompleted={({ me } = {}) => {
            const { project } = me || {},
              { form } = project || {},
              { name } = form || {};
            this.setState({ formName: name });
          }}
          variables={{
            projectId,
            formId,
            startedAt: moment(fromDay).format('YYYY-MM-DD'),
            startedThru: moment(toDay).format('YYYY-MM-DD')
          }}
        >
          {this.renderDataContent}
        </ReactApollo.Query>
        <View style={[styles.padding(30, 10)]}>
          <H2
            style={[
              styles.border(0, 0, 1, 0, styles.getColorCode('primary')),
              styles.color('primary')
            ]}
          >
            Based on Entire Database
          </H2>
        </View>
        <ReactApollo.Query
          query={GET_REPORT}
          onCompleted={({ me } = {}) => {
            const { project } = me || {},
              { form } = project || {},
              { name } = form || {};
            this.setState({ formName: name });
          }}
          variables={{
            projectId,
            formId
          }}
        >
          {props => this.renderDataContent(props, true)}
        </ReactApollo.Query>
      </View>
    );
  }

  renderDataContent({ loading, data }, showResubmit) {
    if (!!loading && !data) return <Loading />;
    const { me } = data || {},
      { project } = me || {},
      { form } = project || {},
      { report } = form || {},
      {
        nosOfSubmitted,
        nosOfPassed,
        nosOfFailed,
        nosOfPendingInspection,
        pending3To15D,
        pending16To30D,
        pending31To90D,
        pendingMore90D,
        averageProgressingTime,
        averagePassRate,
        nosOfResubmit
      } = report || {};
    return (
      <Col>
        <Responsively
          layout={{
            xs: Col,
            sm: Row
          }}
          spacing={10}
          style={[styles.padding(10)]}
        >
          <DataCard label={'Submitted'} value={nosOfSubmitted} />
          <DataCard label={'Approved'} value={nosOfPassed} />
          <DataCard label={'Failed'} value={nosOfFailed} />
          <DataCard
            label={'Pending Inspection'}
            value={nosOfPendingInspection}
          />
          {showResubmit && (
            <DataCard label={'Total nos Resubmit'} value={nosOfResubmit} />
          )}
        </Responsively>
        <Responsively
          layout={{ lg: Row, default: Col }}
          style={[styles.padding(10), { flexWrap: 'wrap' }]}
          spacing={10}
          fy={'center'}
        >
          <View>
            <ChartPie
              data={[
                { label: '', value: 0 },
                { label: 'Submitted', value: nosOfSubmitted },
                {
                  label: 'Pending Inspection',
                  value: nosOfPendingInspection
                },
                { label: 'Failed', value: nosOfFailed },
                { label: 'Passed', value: nosOfPassed }
              ]}
            />
          </View>
          <Flex>
            <Text>Nos. of inspection form pending for final registration</Text>
            <Text>
              (Calculated from the Date of Request to the Date of Contractor
              Acknowledge Results. One complete inspection cycle period.
            </Text>
            {[
              {
                label: 'Pending between 3 to 15 days',
                value: pending3To15D,
                color: 'success'
              },
              {
                label: 'Pending between 16 to 30 days',
                value: pending16To30D,
                color: 'danger'
              },
              {
                label: 'Pending between 31 to 90 days',
                value: pending31To90D,
                color: 'warning'
              },
              {
                label: 'Pending more than 90 days',
                value: pendingMore90D,
                color: '#ba1717'
              }
            ].map(({ label, value, color }, i) => (
              <Row key={i} style={[styles.border(1)]}>
                <Text
                  style={[
                    styles.backgroundColor(color),
                    { width: 80, textAlign: 'center' }
                  ]}
                >
                  {value}
                </Text>
                <Text style={[styles.flex(1)]}>{label}</Text>
              </Row>
            ))}
          </Flex>
          <DataCard2
            label={'Average Processing Time'}
            value={
              averageProgressingTime !== undefined &&
              `${(averageProgressingTime - 0 || 0).toFixed(2) - 0}D`
            }
          />
          <DataCard2
            label={'Total Pass Rate'}
            value={
              averagePassRate !== undefined && `${~~(averagePassRate * 100)}%`
            }
          />
        </Responsively>
      </Col>
    );
  }
}
