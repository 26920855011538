import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FileInputS3_2 } from '../../rnbc/components/form';
import Icon from '../../rnbc/icon';
import Button from '../../rnbc/components/button';

export default class ButtonFileField extends FileInputS3_2 {
  render() {
    const { color } = this.props;
    return (
      <Fragment>
        <Button transparent color={color} onPress={this.onDisplayerPress}>
          <Icon name={'print'} />
        </Button>
        {this.renderModal()}
      </Fragment>
    );
  }
}
ButtonFileField.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  color: PropTypes.string
};
ButtonFileField.defaultProps = {
  disabled: true,
  color: 'light'
};
