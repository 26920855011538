import { ApolloBoost } from '../../../plugins';
const { gql } = ApolloBoost;

export const GET_REPORT = gql`
  query(
    $startedAt: String
    $startedThru: String
    $projectId: ID!
    $formId: ID!
  ) {
    me {
      id
      project(id: $projectId) {
        id
        form(id: $formId) {
          id
          name
          report(startedAt: $startedAt, startedThru: $startedThru) {
            nosOfSubmitted
            nosOfPassed
            nosOfFailed
            nosOfPendingInspection
            nosOfResubmit
            averagePassRate
            averageProgressingTime
            pending3To15D
            pending16To30D
            pending31To90D
            pendingMore90D
          }
        }
      }
    }
  }
`;
